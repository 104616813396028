import React from "react";
import {Link, withRouter} from "react-router-dom";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';

import Axios from "axios";
import Store from "store";

import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import DsInputShortcut from "../../Orders/SingleOrder/CargoItem/DamagesWrapper/Damage/DsInput/DsInputShortcut";
import BaseSelect from 'react-select';
import {getDamageSets, getDamageSetsById} from "../../../../utils/utils";
import ConditionalContent from "../../../../ConditionalContent";

const SuccessModal = withReactContent(Swal);

class AddNewDamageSetShortcut extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            groupName: "",
            selectedGroupDamageSetId: null,
            token: Store.get('accessToken').token,
            dsPart: null,
            dsType: null,
            dsSeverity: null,
            dsCause: null,
            dsRepairMode: null,
            dsClass: null,
            dsDln: null,
            damageSetsArr: [],
            selectedDamageSetId: null,
            damageSetOptions: [],
            createdShortcuts: []
        };


        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.fetchItems = this.fetchItems.bind(this);
        this.handleDamageSetChange = this.handleDamageSetChange.bind(this);
        this.handleGroupDamageSetChange = this.handleGroupDamageSetChange.bind(this);
        this.updateDs = this.updateDs.bind(this);
        this.handleChangeName = this.handleChangeName.bind(this);
        this.deleteShortcut = this.deleteShortcut.bind(this);
    }

    componentDidMount() {

        this.state.createdShortcuts.push({
            dsPart: null,
            dsType: null,
            dsSeverity: null,
            dsCause: null,
            dsRepairMode: null,
            dsClass: null,
            dsDln: null,
            name: null
        })
        this.setState({
            
        })

        // Get DamageSets
        let tempDamageSets = [];
        getDamageSets()
        .then(resp => {
            for (const item of resp) {
                tempDamageSets.push({
                    'value': item.id,
                    'label': item.name
                });
            }
        })
        .then(() => {
            this.setState({
                damageSetsArr: tempDamageSets
            });
        });    

        if (this.state.token) {
            this.fetchItems();
        }
    }

    fetchItems() {
        Axios.get(process.env.REACT_APP_API_BASE + '/shortcuts', {
            headers: {
                'Authorization': 'Bearer ' + this.state.token
            },
        } ).then(response => {
            console.log(response);
            if (response.status === 200) {
                this.setState({
                    items: response.data.shortcuts
                })
            }
        }).catch(error => {
            console.log('error', error);
            if (error.status === 401) {
                console.log('attempting reauth');
            }
        });
    }


    handleSubmit(e) {
        e.preventDefault();
    
        // Check if there are created shortcuts
        if (this.state.createdShortcuts.length <= 0) {
            SuccessModal.fire({
                title: 'You need to have at least one shortcut.',
                icon: 'error',
            });
            return; // Exit early to avoid unnecessary processing
        }
    
        // Prepare the group form data
        const groupFormData = {
            name: this.state.groupName,
            damage_set_id: this.state.selectedGroupDamageSetId,
        };
    
        // API call to create a group
        Axios.post(`${process.env.REACT_APP_API_BASE}/groups`, groupFormData, {
            headers: {
                Authorization: `Bearer ${this.state.token}`,
            },
        })
            .then((response) => {
                if (response.status === 200) {
                    const groupId = response.data.shortcut_group?.id;
    
                    if (!groupId) {
                        console.error("Shortcut group ID missing in the response.");
                        return;
                    }
    
                    // Prepare and send shortcut data for each created shortcut
                    this.state.createdShortcuts.forEach((shortcut) => {
                        const formData = {
                            name: shortcut.name,
                            group_id: groupId,
                            ds_part: shortcut.dsPart?.id || '',
                            ds_type: shortcut.dsType?.id || '',
                            ds_severity: shortcut.dsSeverity?.id || '',
                            ds_cause: shortcut.dsCause?.id || '',
                            ds_repair_mode: shortcut.dsRepairMode?.id || '',
                            ds_class: shortcut.dsClass?.id || '',
                            ds_dln: shortcut.dsDln?.id || '',
                        };
    
                        Axios.post(`${process.env.REACT_APP_API_BASE}/shortcuts`, formData, {
                            headers: {
                                Authorization: `Bearer ${this.state.token}`,
                            },
                        })
                            .then((shortcutResponse) => {
                                console.log('Shortcut added:', shortcutResponse.data);
                            })
                            .catch((shortcutError) => {
                                console.error('Error adding shortcut:', shortcutError.response?.data?.message || shortcutError);
                            });
                    });
    
                    // Success notification for the group creation
                    SuccessModal.fire({
                        title: 'Successfully added Shortcut group',
                    }).then(() => {
                        this.props.history.push('/dashboard/damage-sets-shortcuts');
                    });
                }
            })
            .catch((error) => {
                const errorMessage = error.response?.data?.message || 'An error occurred.';
                console.error('Error creating group:', errorMessage);
    
                this.setState({
                    formErrors: errorMessage,
                });
    
                if (error.response?.status === 403) {
                    SuccessModal.fire({
                        title: 'Forbidden!',
                    }).then(() => {
                        this.props.history.push('/dashboard/damage-sets-shortcuts');
                    });
                }
            });
    }


    deleteShortcut(e, index) {

        e.preventDefault();

        Swal.fire({
            title: "Are you sure that you want to delete shortcut?",
            showDenyButton: true,
            showCancelButton: false,
            confirmButtonText: "No, cancel",
            denyButtonText: `Yes, delete!`
          }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
                Swal.fire("Operation canceled!", "", "info");  
              
            } else if (result.isDenied) {
                this.state.createdShortcuts.splice(index, 1);
                this.setState({});
                Swal.fire("Shortcut deleted!", "", "success");
            }
          });
    }

    updateDs(name, value, index) {
    
        const createdShortcuts = [...this.state.createdShortcuts];
        createdShortcuts[index][name] = value;
        this.setState({ createdShortcuts });
    }

    handleChange(e) {

        //Sets state with the appropriate key, to the matching input's value
        this.setState({
            [e.target.name]: e.target.value
        });
    }

    handleDamageSetChange(obj, e) {

        getDamageSetsById(obj.value)
        .then(resp => {
            console.log(resp);
            this.setState({
                selectedDamageSetId: obj.value,
                damageSetOptions: resp.damageSetOptions
            })
        })
    }

    handleChangeName(name, value, index) {
        const createdShortcuts = [...this.state.createdShortcuts];
        createdShortcuts[index][name]= value;
        this.setState({ createdShortcuts });
    }

    handleGroupDamageSetChange(obj, e) {
        getDamageSetsById(obj.value)
        .then(resp => {
            console.log(resp);
            this.setState({
                selectedGroupDamageSetId: obj.value,
                damageSetOptions: resp.damageSetOptions
            })
        })
    }

    addAdditionalShortcut(e) {
        e.preventDefault();
        this.state.createdShortcuts.push({
            dsPart: null,
            dsType: null,
            dsSeverity: null,
            dsCause: null,
            dsRepairMode: null,
            dsClass: null,
            dsDln: null,
            name: null
        })
        this.setState({
            
        })
        console.log("ADDING ADDITIONAL SHORTCUT " + this.state.createdShortcuts.length);
    }

    render() {

        return (
            <div className="container" id="main">
                <div className="row mb-2">
                    <div className="col-12 col-md-8">
                        <Link to={"/dashboard/damage-sets-shortcuts/"} className={"btn-min"}>
                            <FontAwesomeIcon icon={faChevronLeft} />
                            <span className="ml-1">View all damage set shortcuts</span>
                        </Link>
                        <h1 className={"mt-1"}>Add new Damage Set Shortcut Group</h1>
                    </div>
                </div>
            <form action="" onSubmit={this.handleSubmit}>
                <div className="row mb-4">
                    <div className="col-6 col-lg-6">
                        <label className="mb-2" htmlFor="name">Group name</label>
                        <input type="text" className={"form-control"} name={"groupName"} id={"groupName"} value={this.state.groupName?.split('|')[0]} onChange={this.handleChange}/>
                    </div>
                    <div className="col-6 col-lg-6">
                        <label className="mb-2">Group damage set</label>
                        <div className="">
                        <BaseSelect
                            closeMenuOnSelect={true}
                            options={this.state.damageSetsArr}
                            name="damageSetId"
                            className="basic-multi-select"
                            classNamePrefix="select"
                            onChange={this.handleGroupDamageSetChange}
                        />
                        </div>
                    </div>
                </div>
                {
                    this.state.createdShortcuts.map((item, index) => {
                        return <div className="card p-4 mt-2">
                        <div className="row mb-3">
                            <div className="col-6 col-lg-6">
                                <label className="mb-2" htmlFor="name">Shortcut name</label>
                                <input type="text" className={"form-control"} name={"name"} id={"name"} value={this.state.createdShortcuts[index].name} onChange={(e) => this.handleChangeName(e.target.name, e.target.value, index)} required/>
                            </div>
                            <label className="mt-2 mb-2">Damage set options</label>
                            <ConditionalContent check={this.state.damageSetOptions.length !== 0}>
                                <div className="layout-grid-7">
    
                                    <div className="ds-item">
                                        <DsInputShortcut existingValue={this.state.createdShortcuts[index].dsPart} type={"part"} label={"Part"} name={"dsPart"} updateParent={(name, value) => this.updateDs(name, value, index)} damageSetOptions={this.state.damageSetOptions} />
                                    </div>
                                    <div className="ds-item">
                                        <DsInputShortcut existingValue={this.state.createdShortcuts[index].dsType} type={"type"} label={"Type"} name={"dsType"} updateParent={(name, value) => this.updateDs(name, value, index)}  damageSetOptions={this.state.damageSetOptions}/>
                                    </div>
                                    <div className="ds-item">
                                        <DsInputShortcut existingValue={this.state.createdShortcuts[index].dsSeverity} type={"severity"} label={"Severity"} name={"dsSeverity"} updateParent={(name, value) => this.updateDs(name, value, index)}  damageSetOptions={this.state.damageSetOptions}/>
                                    </div>
                                    <div className="ds-item">
                                        <DsInputShortcut existingValue={this.state.createdShortcuts[index].dsCause} type={"cause"} label={"Cause"} name={"dsCause"} updateParent={(name, value) => this.updateDs(name, value, index)}  damageSetOptions={this.state.damageSetOptions}/>
                                    </div>
                                    <div className="ds-item">
                                        <DsInputShortcut existingValue={this.state.createdShortcuts[index].dsRepairMode} type={"repair_mode"} label={"Repair Mode"} name={"dsRepairMode"} updateParent={(name, value) => this.updateDs(name, value, index)}  damageSetOptions={this.state.damageSetOptions}/>
                                    </div>
                                    <div className="ds-item">
                                        <DsInputShortcut existingValue={this.state.createdShortcuts[index].dsClass} type={"class"} label={"Class"} name={"dsClass"} updateParent={(name, value) => this.updateDs(name, value, index)}  damageSetOptions={this.state.damageSetOptions}/>
                                    </div>
                                    <div className="ds-item">
                                        <DsInputShortcut existingValue={this.state.createdShortcuts[index].dsDln} type={"dln"} label={"DLN"} name={"dsDln"} updateParent={(name, value) => this.updateDs(name, value, index)}  damageSetOptions={this.state.damageSetOptions}/>
                                    </div>
                                </div>
                            </ConditionalContent>
                            <ConditionalContent check={this.state.damageSetOptions.length === 0}>
                                <p>Damage set is not selected.</p>
                            </ConditionalContent>
                        </div>
                        <div className="col-12">
                                <button onClick={(e) => this.deleteShortcut(e, index)}className={"btn btn-danger ms-2 float-end"}>Delete shortcut</button>
                            </div>
                    </div>
                    })
                }
                

                <div className="mt-2 mb-2">
                    <button onClick={(e) => this.addAdditionalShortcut(e)} className={"btn btn-primary float-end"}>+</button>     
                </div>
                
                               
                <div className={"mt-4"}>
                    <button type={"submit"} className={"btn btn-primary"}>Create shortcut</button>
                </div>                    

            </form>
            </div>
        );
    }
}

AddNewDamageSetShortcut.propTypes = {};

export default withRouter(AddNewDamageSetShortcut);
