import React from "react";
import {Link, withRouter} from "react-router-dom";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';

import Axios from "axios";
import Store from "store";

import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import DsInputShortcut from "../../Orders/SingleOrder/CargoItem/DamagesWrapper/Damage/DsInput/DsInputShortcut";
import BaseSelect from 'react-select';
import {getDamageSets, getDamageSetsById, getDamageSetShortcutById, getDamageSetShortcutGroupById} from "../../../../utils/utils";
import ConditionalContent from "../../../../ConditionalContent";
import { array } from "prop-types";
import { filter } from "underscore";

const SuccessModal = withReactContent(Swal);

class EditDamageSetShortcut extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            name: '',
            groupName: '',
            shortcutId: this.props.match.params.entryId,
            token: Store.get('accessToken').token,
            dsPart: null,
            dsType: null,
            dsSeverity: null,
            dsCause: null,
            dsRepairMode: null,
            dsClass: null,
            dsDln: null,
            damageSetsArr: [],
            selectedDamageSetId: null,
            damageSetOptions: [],
            selectedDamageSetObject: null,
            selectedShortcut: null,
            damageSetId: this.props.match.params.damageSetId,
            createdShortcuts: [],
            createdShortcutsObjs: []
        };

        
        this.deleteShortcut = this.deleteShortcut.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleDamageSetChange = this.handleDamageSetChange.bind(this);
        this.updateDs = this.updateDs.bind(this);
        this.handleGroupDamageSetChange = this.handleGroupDamageSetChange.bind(this);
        this.returnShortcutObj = this.returnShortcut.bind(this);
        this.handleChangeName = this.handleChangeName.bind(this);
    }

    handleGroupDamageSetChange(obj, e) {
        getDamageSetsById(obj.value)
        .then(resp => {
            console.log(resp);
            this.setState({
                selectedGroupDamageSetId: obj.value,
                damageSetOptions: resp.damageSetOptions
            })
        })
    }

    async componentDidMount() {
        let respDamageSetId = -1;

        await getDamageSetShortcutGroupById(this.props.match.params.entryId).then(resp => {
            var groupName = resp.group.name;
            respDamageSetId = resp.group.damage_set.id;

            this.setState({
                damageSetId: respDamageSetId,
                groupName: groupName,
            })
        });

        await getDamageSetShortcutById(this.props.match.params.entryId, this.props.match.params.damageSetId).then(resp => {
          


            resp.shortcuts.forEach(element => {
                this.state.createdShortcuts.push({
                    id: element.id,
                    name: element.name,
                    damage_set_id: element.damage_set_id,
                    dsPart: element.ds_part,
                    dsType: element.ds_type,
                    dsSeverity: element.ds_severity,
                    dsCause: element.ds_cause,
                    dsRepairMode:element.ds_repair_mode,
                    dsClass: element.ds_class,
                    dsDln: element.ds_dln,
                });
            });
        });

        /*this.setState({
            name: displayName,
            selectedDamageSetId: respDamageSetId,
        });*/

        await getDamageSetsById(this.state.damageSetId).then(resp => {
            this.setState({
                selectedDamageSetId: respDamageSetId,
                damageSetOptions: resp.damageSetOptions
            })
        })

        this.state.createdShortcuts.forEach((element) => {
            element.dsPart = this.state.damageSetOptions.filter(
                (item) => item.id === element.dsPart
            )[0] || null;

            element.dsType = this.state.damageSetOptions.filter(
                (item) => item.id === element.dsType
            )[0] || null;

            
            element.dsSeverity = this.state.damageSetOptions.filter(
                (item) => item.id === element.dsSeverity
            )[0] || null;

            
            element.dsCause = this.state.damageSetOptions.filter(
                (item) => item.id === element.dsCause
            )[0] || null;

            
            element.dsRepairMode = this.state.damageSetOptions.filter(
                (item) => item.id === element.dsRepairMode
            )[0] || null;

            
            element.dsClass = this.state.damageSetOptions.filter(
                (item) => item.id === element.dsClass
            )[0] || null;

            
            element.dsDln = this.state.damageSetOptions.filter(
                (item) => item.id === element.dsDln
            )[0] || null;

            
        })

        // Get DamageSets
        let tempDamageSets = [];
        let tempSelectedDamageSet = null;
        getDamageSets()
        .then(resp => {
            for (const item of resp) {
                
                tempDamageSets.push({
                    'value': item.id,
                    'label': item.name
                });
            }

            for (const item of tempDamageSets) {
                if(item.value === this.state.damageSetId) {
                    this.setState({
                        selectedDamageSetObject: item
                    })
                }
            }
        })
        .then(() => {
            this.setState({
                damageSetsArr: tempDamageSets,
            });
        });    


        if (this.state.token) {
            // this.fetchItems();
        }
        
        this.setState({
            selectedDamageSetObject: tempSelectedDamageSet
        })

        /*if(this.state.damageSetOptions !== null) {
            console.log("DAMAGE SET OPTIONS")
            if(this.state.createdShortcuts.length > 0) {
                console.log(this.state.damageSetOptions);
                const filteredOptions = this.state.damageSetOptions.filter(
                    (item) => item.id === this.state.createdShortcuts[0]?.dsPart
                );
                console.log("Najden item: " + this.state.createdShortcuts[0].dsPart );

                this.state.damageSetOptions.forEach((element) => {
                    if(element.id===this.state.createdShortcuts[0].dsPart) {
                        console.log(element);
                    }
                }
                    
                );
            }
            if(this.state.selectedShortcut !== null) {
                console.log("DAMAGE SET SELECTED SHORTCUT")
                this.setState({
                    dsPart: this.state.selectedShortcut.ds_part !== 0 ? this.state.damageSetOptions.find(el => el.id === this.state.selectedShortcut.ds_part) : null,
                    dsType: this.state.selectedShortcut.ds_type !== 0 ? this.state.damageSetOptions.find(el => el.id === this.state.selectedShortcut.ds_type) : null,
                    dsSeverity: this.state.selectedShortcut.ds_severity !== 0 ? this.state.damageSetOptions.find(el => el.id === this.state.selectedShortcut.ds_severity) : null,
                    dsRepairMode: this.state.selectedShortcut.ds_repair_mode !== 0 ? this.state.damageSetOptions.find(el => el.id === this.state.selectedShortcut.ds_repair_mode) : null,
                    dsCause: this.state.selectedShortcut.ds_cause !== 0 ? this.state.damageSetOptions.find(el => el.id === this.state.selectedShortcut.ds_cause) : null,
                    dsClass: this.state.selectedShortcut.ds_class !== 0 ? this.state.damageSetOptions.find(el => el.id === this.state.selectedShortcut.ds_class) : null, 
                    dsDln: this.state.selectedShortcut.ds_dln !== 0 ? this.state.damageSetOptions.find(el => el.id === this.state.selectedShortcut.ds_dln) : null
                })
            }
        }*/
    }

    returnShortcut(name) {

        console.log(name);
        if(this.state.createdShortcuts !== null) {

            this.state.damageSetOptions.forEach((el) => {
                if(el.id === name) {
                    return el;
                }
            })
            var filteredOptions = this.state.damageSetOptions.filter(
                (item) => item.id === 174
            );

            console.log(filteredOptions);

            return filteredOptions[0];
        }
    }


    handleSubmit(e) {
        e.preventDefault();

        // Prepare the group form data
        const groupFormData = {
            name: this.state.groupName,
            damage_set_id: this.state.selectedGroupDamageSetId,
        };

        Axios.put(`${process.env.REACT_APP_API_BASE}/groups/` + this.props.match.params.entryId, groupFormData, {
            headers: {
                Authorization: `Bearer ${this.state.token}`,
            },
        })
    
        const shortcutPromises = this.state.createdShortcuts.map((element) => {
            const formData = {
                damage_set_id: this.state.damageSetId,
                name: element.name,
                group_id: this.props.match.params.entryId,
                ds_part: element.dsPart?.id || '',
                ds_type: element.dsType?.id || '',
                ds_severity: element.dsSeverity?.id || '',
                ds_cause: element.dsCause?.id || '',
                ds_repair_mode: element.dsRepairMode?.id || '',
                ds_class: element.dsClass?.id || '',
                ds_dln: element.dsDln?.id || '',
            };
    
            if (element.id === undefined) {
                // Handle new shortcut (POST)
                console.log("Creating new shortcut...");
                return Axios.post(`${process.env.REACT_APP_API_BASE}/shortcuts`, formData, {
                    headers: {
                        Authorization: `Bearer ${this.state.token}`,
                    },
                });
            } else {
                // Handle existing shortcut (PUT)
                console.log("Updating shortcut with ID:", element.id);
                formData.name = formData.name?.split('|')[0]; // Ensure name formatting
                return Axios.put(`${process.env.REACT_APP_API_BASE}/shortcuts/${element.id}`, formData, {
                    headers: {
                        Authorization: `Bearer ${this.state.token}`,
                    },
                });
            }
        });
    
        // Wait for all shortcut promises to complete
        Promise.all(shortcutPromises)
            .then((responses) => {
                console.log("All shortcuts processed:", responses);
    
                // Show success modal after all operations are complete
                SuccessModal.fire({
                    title: 'Successfully updated Shortcut group',
                }).then(() => {
                    this.props.history.push('/dashboard/damage-sets-shortcuts');
                });
            })
            .catch((error) => {
                console.error("Error processing shortcuts:", error);
    
                const errorMessage = error.response?.data?.message || 'An error occurred.';
                this.setState({
                    formErrors: errorMessage,
                });
    
                if (error.response?.status === 403) {
                    SuccessModal.fire({
                        title: 'Forbidden!',
                    }).then(() => {
                        this.props.history.push('/dashboard/damage-sets-shortcuts');
                    });
                }
            });
    }

    updateDs(name, value, index) {
        const createdShortcuts = [...this.state.createdShortcuts];
        createdShortcuts[index][name] = value;
        this.setState({ createdShortcuts });
    }
    handleChangeName(name, value, index) {
        const createdShortcuts = [...this.state.createdShortcuts];
        createdShortcuts[index][name]= value;
        this.setState({ createdShortcuts });
    }
    handleChange(e) {

        //Sets state with the appropriate key, to the matching input's value

            this.setState({
                [e.target.name]: e.target.value
            });

    }

    handleDamageSetChange(obj, e) {
        getDamageSetsById(obj.value)
        .then(resp => {
            this.setState({
                selectedDamageSetObject: obj,
                selectedDamageSetId: obj.value,
                damageSetOptions: resp.damageSetOptions
            })
        })
    }

    addAdditionalShortcut(e) {
        e.preventDefault();
        this.state.createdShortcuts.push({
            name: '',
            dsPart: null,
            dsType: null,
            dsSeverity: null,
            dsCause: null,
            dsRepairMode: null,
            dsClass: null,
            dsDln: null,
        })
        this.setState({
            
        })
        console.log("ADDING ADDITIONAL SHORTCUT " + this.state.createdShortcuts.length);
    }

    deleteShortcut(e, index) {
        e.preventDefault();

        Swal.fire({
            title: "Are you sure that you want to delete shortcut?",
            showDenyButton: true,
            showCancelButton: false,
            confirmButtonText: "No, cancel",
            denyButtonText: `Yes, delete!`
          }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
                Swal.fire("Operation canceled!", "", "info");  
              
            } else if (result.isDenied) {
                Axios.delete(process.env.REACT_APP_API_BASE + '/groups/' + this.props.match.params.entryId, {
                    headers: {
                        'Authorization': 'Bearer ' + Store.get('accessToken').token
                    }
                }).then(r => {

                    if(r.status === 200) {
                        SuccessModal.fire({
                            'icon': 'success',
                            'title': 'Successfully deleted shortcut'
                        });
            
                        this.props.history.push('/dashboard/damage-sets-shortcuts');
                        Swal.fire("Shortcut deleted!", "", "success");

                    }
                   
                }).catch((error) => {
                    const errorMessage = error.response?.data?.message || 'An error occurred.';
                    this.setState({
                        formErrors: errorMessage,
                    });
        
                    if (error.response?.status === 403) {
                        SuccessModal.fire({
                            title: 'Forbidden! No permission to delete this',
                        }).then(() => {
                            this.props.history.push('/dashboard/damage-sets-shortcuts');
                        });
                    }
                });
            }
          });
    }

    render() {

        return (
            <div className="container" id="main">
                <div className="row mb-5">
                    <div className="col-12 col-md-8">
                        <Link to={"/dashboard/damage-sets-shortcuts/"} className={"btn-min"}>
                            <FontAwesomeIcon icon={faChevronLeft} />
                            <span className="ml-1">View all damage set shortcuts</span>
                        </Link>
                        <h1 className={"mt-1"}>Edit Damage Set Shortcut group</h1>
                    </div>
                </div>
                <div className="row mb-4">
                    <div className="col-6 col-lg-6">
                        <label className="mb-2" htmlFor="name">Group name</label>
                        <input type="text" className={"form-control"} name={"groupName"} id={"groupName"} value={this.state.groupName?.split('|')[0]} onChange={this.handleChange}/>
                    </div>
                    <div className="col-6 col-lg-6">
                        <label className="mb-2">Group damage set</label>
                        <div className="">
                        <BaseSelect
                            closeMenuOnSelect={true}
                            options={this.state.damageSetsArr}
                            name="damageSetId"
                            className="basic-multi-select"
                            classNamePrefix="select"
                            value={this.state.selectedDamageSetObject}
                            onChange={this.handleGroupDamageSetChange}
                        />
                        </div>
                    </div>
                </div>
                {
                    this.state.createdShortcuts.map((item, index) => {
                        return <div className="card p-4 mt-2" key={index}>
                        <div className="row mb-3">
                            <div className="col-6 col-lg-6">
                                <label className="mb-2" htmlFor="name">Shortcut name</label>
                                <input type="text" className={"form-control"} name={"name"} id={"name"} value={this.state.createdShortcuts[index].name} onChange={(e) => this.handleChangeName(e.target.name, e.target.value, index)}/>
                            </div>
                            <label className="mt-2 mb-2">Damage set options</label>
                            <ConditionalContent check={this.state.damageSetOptions.length !== 0}>
                                <div className="layout-grid-7">
    
                                    <div className="ds-item">
                                        <DsInputShortcut existingValue={this.state.createdShortcuts[index].dsPart} type={"part"} label={"Part"} name={"dsPart"} updateParent={(name, value) => this.updateDs(name, value, index)} damageSetOptions={this.state.damageSetOptions} />
                                    </div>
                                    <div className="ds-item">
                                        <DsInputShortcut existingValue={this.state.createdShortcuts[index].dsType} type={"type"} label={"Type"} name={"dsType"} updateParent={(name, value) => this.updateDs(name, value, index)} damageSetOptions={this.state.damageSetOptions}/>
                                    </div>
                                    <div className="ds-item">
                                        <DsInputShortcut existingValue={this.state.createdShortcuts[index].dsSeverity} type={"severity"} label={"Severity"} name={"dsSeverity"} updateParent={(name, value) => this.updateDs(name, value, index)} damageSetOptions={this.state.damageSetOptions}/>
                                    </div>
                                    <div className="ds-item">
                                        <DsInputShortcut existingValue={this.state.createdShortcuts[index].dsCause}  type={"cause"} label={"Cause"} name={"dsCause"} updateParent={(name, value) => this.updateDs(name, value, index)} damageSetOptions={this.state.damageSetOptions}/>
                                    </div>
                                    <div className="ds-item">
                                        <DsInputShortcut existingValue={this.state.createdShortcuts[index].dsRepairMode} type={"repair_mode"} label={"Repair Mode"} name={"dsRepairMode"} updateParent={(name, value) => this.updateDs(name, value, index)} damageSetOptions={this.state.damageSetOptions}/>
                                    </div>
                                    <div className="ds-item">
                                        <DsInputShortcut existingValue={this.state.createdShortcuts[index].dsClass} type={"class"} label={"Class"} name={"dsClass"} updateParent={(name, value) => this.updateDs(name, value, index)} damageSetOptions={this.state.damageSetOptions}/>
                                    </div>
                                    <div className="ds-item">
                                        <DsInputShortcut existingValue={this.state.createdShortcuts[index].dsDln} type={"dln"} label={"DLN"} name={"dsDln"} updateParent={(name, value) => this.updateDs(name, value, index)} damageSetOptions={this.state.damageSetOptions}/>
                                    </div>
                                </div>
                            </ConditionalContent>
                            <ConditionalContent check={this.state.damageSetOptions.length === 0}>
                                <p>Damage set is not selected.</p>
                            </ConditionalContent>
                            
                        </div>
                            <div className="col-12">
                                <button onClick={(e) => this.deleteShortcut(e, index)}className={"btn btn-danger ms-2 float-end"}>Delete shortcut</button>
                            </div>
                    </div>
                    })
                }
                
                <div className="mt-2 mb-2">
                    <button onClick={(e) => this.addAdditionalShortcut(e)} className={"btn btn-primary float-end"}>+</button>     
                </div>
                
                               

                <div className={"mt-4"}>
                    <button type={"submit"} onClick={this.handleSubmit} className={"btn btn-primary"}>Update shortcut</button>
                    {<button onClick={(e) => this.deleteShortcut(e)}className={"btn btn-danger ms-2"}>Delete group</button>}
                </div>                    

            </div>
        );
    }
}

EditDamageSetShortcut.propTypes = {};

export default withRouter(EditDamageSetShortcut);
