import React from "react";

import Style from "./Navigation.module.scss";
import "./Navigation.scss";
import {
    BrowserRouter as Router, NavLink,
    withRouter
} from "react-router-dom";

import {isAdmin, isManager, logout} from '../../../utils/utils';
import Search from "./Search/Search";
import AdvancedSearch from "./AdvancedSearch/AdvancedSearch";
import ConditionalContent from "../../../ConditionalContent";



class Navigation extends React.Component {

    constructor(props) {
        super(props);

        this.logout = this.logout.bind(this);
        this.toggleButton = this.toggleButton.bind(this);

        this.state = {
            sidebarActive: true
        }
    }



    logout() {
        /* eslint-disable */
        const toLogout = confirm("Are you sure to logout ?");
        /* eslint-enable */
        if (toLogout) {
            logout();
            this.props.history.push('/');
        }
    }

    toggleButton() {
        const currentState = this.state.sidebarActive;
        this.setState({ sidebarActive: !currentState });
    }


    render() {
        // const { value, suggestions } = this.state;
        //
        // // Autosuggest will pass through all these props to the input.
        // const inputProps = {
        //     placeholder: 'Enter search input',
        //     value,
        //     onChange: this.onChange
        // };

        const showWarningHeader = process.env.REACT_APP_SHOW_WARNING_HEADER === 'true';


        return (
            <div className={Style.navbar}>
                <nav className={"navbar navbar-expand-lg d-flex w-100 px-3"}>

                    <div className="logo">
                        <h2 className={"mb-0"}>Ares</h2>
                    </div>
                    <div className={"d-flex justify-content-between w-100 ps-3"}>
                        <ConditionalContent check={isAdmin()}>
                            <div className={Style.searchWrapper}>
                                <Search/>
                                <AdvancedSearch />
                            </div>
                        </ConditionalContent>
                        <ConditionalContent check={isManager()}>
                            <div className={Style.searchWrapper}>
                                <Search/>
                                <AdvancedSearch />
                            </div>
                        </ConditionalContent>


                        <div className={Style.navWrapper + " collapse navbar-collapse ms-auto"} id="navbarText">

                            <span className={Style.name + " navbar-text"}>{this.props.user ? this.props.user.name : "Tihomir Mačak"}</span>
                            <Router>
                                <ul className="navbar-nav">
                                    <li className="nav-item">
                                    <span
                                        className={Style.logout + " nav-link cursor-pointer text-secondary"}
                                        onClick={this.logout}>
                                        Sign out
                                    </span>
                                    </li>
                                </ul>
                            </Router>
                        </div>
                    </div>
                </nav>
            </div>
        );
    }
}


export default withRouter(Navigation);
