import React from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import 'react-datepicker/dist/react-datepicker-cssmodules.css';
import Store from "store";
import {
    fetchItemsFromArr,
    getInspector,
    getMake,
    getModel,
    getPorts,
    getTransport, getTransportCompanies,
    getTransportCompany,
    getInspectusComments,
    getPort,
    fetchPortsFromArr
} from "../../../../../utils/utils";

//JSON imports

import ModelFilter from "./ModelFilter/ModelFilter";

import Style from "./Filters.module.scss";
import DsInput from "../CargoItem/DamagesWrapper/Damage/DsInput/DsInput";
import BaseSelect from 'react-select';


class Filters extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            items: this.props.items,
            keyword: null,
            orderId: this.props.orderId,
            token: Store.get('accessToken').token,


            transportCompanyOptions: [],
            countriesOptions: [],
            voyageOptions: [],
            dsPartOptions: [],
            transportModes: [],

            transportsArr: null,
            modelsArr: null,
            makesArr: null,
            portsArr: null,
            transshipmentPortsArr: null,
            portOfDischargingArr: null,
            portsOfOriginArr: null,
            transportCompany: '',
            //Filters
            vin: '',
            transport: '',
            portOfOrigin: '',
            transshipmentPort: '',
            portOfDischarging: '',
            destination: '',
            bl: '',
            voyage: '',
            fromDate: '',
            toDate: '',
            make: '',
            model: '',
            clientComment: '',
            inspectusComment: '',
            transportMode: '',
            inspectedBy: '',
            damageSetPart: '',
            damageSetSeverity: '',
            damageSetType: '',
            damageSetCause: '',
            damageSetRepairMode: '',
            damageSetClass: '',
            damageSetDln: '',
            inspected: false,
            uninspected: false,
            damaged: false,
            undamaged: false,
            images: false,
            reports: false,
            perPage: 15,
            clearFiltersClicked: false
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleDamageSetChange = this.handleDamageSetChange.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.clearFilters = this.clearFilters.bind(this);
        this.handleSelectChange = this.handleSelectChange.bind(this);
        this.populateSelectFields = this.populateSelectFields.bind(this);
        this.handlePerPageChange = this.handlePerPageChange.bind(this);
        this.handleInspectedInputChange = this.handleInspectedInputChange.bind(this);
        this.handleDamagedInputChange = this.handleDamagedInputChange.bind(this);
        
    }

    async populateSelectFields() {
        let transportCompanyOptions = [],
            blOptions,
            countriesOptions,
            voyageOptions,
            transportModes,
            dsPartOptions,
            dsTypeOptions,
            dsSeverityOptions,
            dsCauseOptions,
            dsRepairModeOptions,
            dsClassOptions,
            dsDlnOptions,
            portsArr = [];

        getTransportCompanies()
            .then(r => {
                    for (const item of r) {
                        transportCompanyOptions.push({
                            'value': item.id,
                            'label': item.name
                        });
                    }
            });

        blOptions = this.props.bls?.map(item => {
            return {
                'value': item,
                'label': item
            }
        });

        countriesOptions = this.props.countries?.map(item => {
            return {
                'value': item,
                'label': item
            }
        });

        voyageOptions = this.props.voyages?.map(item => {
            return {
                'value': item,
                'label': item
            }
        });

        transportModes = this.props.transportModes?.map(item => {
            return {
                'value': item.id,
                'label': item.field_1 + (item.field_2 != null ? '-'+ item.field_2 :   "")
            }
        });

        transportModes.push({
            'value': -1,
            'label': 'Not provided'
        });


        //DS FILTERS
        dsPartOptions = this.props.damageSetData?.filter(i => i.type === 'part')
            .map(item => {
                return {
                    'value': item.id,
                    'label': item.code + '-' + item.name
                }
        });
        dsTypeOptions = this.props.damageSetData?.filter(i => i.type === 'type')
            .map(item => {
                return {
                    'value': item.id,
                    'label': item.code + '-' + item.name
                }
            });
        dsSeverityOptions = this.props.damageSetData?.filter(i => i.type === 'severity')
            .map(item => {
                return {
                    'value': item.id,
                    'label': item.code + '-' + item.name
                }
            });
        dsCauseOptions = this.props.damageSetData?.filter(i => i.type === 'cause')
            .map(item => {
                return {
                    'value': item.id,
                    'label': item.code + '-' + item.name
                }
            });
        dsRepairModeOptions = this.props.damageSetData?.filter(i => i.type === 'repair_mode')
            .map(item => {
                return {
                    'value': item.id,
                    'label': item.code + '-' + item.name
                }
            });
        dsClassOptions = this.props.damageSetData?.filter(i => i.type === 'class')
            .map(item => {
                return {
                    'value': item.id,
                    'label': item.code + '-' + item.name
                }
            });
        dsDlnOptions = this.props.damageSetData?.filter(i => i.type === 'dln')
            .map(item => {
                return {
                    'value': item.id,
                    'label': item.code + '-' + item.name
                }
            });


        if (this.props.makes && this.props.makes.length > 0) {
            fetchItemsFromArr(this.props.makes, getMake).then(resp => {
                this.setState({
                    makesArr: resp
                })

                console.log('makes arr');
                let newMakes = [...this.state.makesArr];
                newMakes.push({
                    id: null,
                    name: 'Unknown'
                })
                this.setState({
                    makesArr: newMakes
                });
            });
        }

        

        if (this.props.transshipmentPorts && this.props.transshipmentPorts.length > 0) {
            fetchPortsFromArr(this.props.transshipmentPorts, getPort).then(resp => {
                console.log('this is resp');
                console.log(resp);
                this.setState({
                    transshipmentPortsArr: resp
                })
            });
        }

        if (this.props.portsOfDischarging && this.props.portsOfDischarging.length > 0) {
            fetchPortsFromArr(this.props.portsOfDischarging, getPort).then(resp => {
                this.setState({
                    portOfDischargingArr: resp
                })
            });
        }

        if (this.props.portsOfOrigin && this.props.portsOfOrigin.length > 0) {
            fetchPortsFromArr(this.props.portsOfOrigin, getPort).then(resp => {
                this.setState({
                    portsOfOriginArr: resp
                })
            });
        }

        if (this.props.models && this.props.models.length > 0) {
            fetchItemsFromArr(this.props.models, getModel).then(resp => {
                this.setState({
                    modelsArr: resp
                })

                let newModels = [...this.state.modelsArr];
                newModels.push({
                    id: null,
                    name: 'Unknown'
                })
                this.setState({
                    modelsArr: newModels
                });
            });
        }

        if (this.props.transports && this.props.transports.length > 0) {
            let transports = this.props.transports.filter(item => {
                return item;
            });
            fetchItemsFromArr(transports, getTransport).then(resp => {
                this.setState({
                    transportsArr: resp
                })
            });
        }

        getPorts()
            .then(resp => {
                for (const item of resp) {
                    portsArr.push({
                        'value': item.id,
                        'label': item.name + ' (' + item.code + ')'
                    });
                }
            })

        let inspectors = [];
        fetchItemsFromArr(this.props.inspectors, getInspector)
            .then(resp => {
                for (const item of resp) {
                    inspectors.push({
                        'value': item.id,
                        'label': item.name + ' (' + item.email + ')'
                    });
                }
        });

        let inspectusComments = [];
        getInspectusComments().then(resp => {
            for (const item of resp) {
                    inspectusComments.push({
                        'value': item.comment,
                        'label': item.comment
                    });
                }
        });
        
        this.setState({
            transportCompanyOptions,
            blOptions,
            inspectors,
            inspectusComments,
            transportModes,
            portsArr,
            countriesOptions,
            voyageOptions,
            dsPartOptions,
            dsTypeOptions,
            dsSeverityOptions,
            dsCauseOptions,
            dsRepairModeOptions,
            dsClassOptions,
            dsDlnOptions,
        });
    }


    componentDidMount() {
        this.populateSelectFields();
    }

    componentDidUpdate(prevProps) {
        if (prevProps !== this.props) {
            this.populateSelectFields();
        }
    }



    handleChange(e) {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    setFromDate(date) {

        this.setState({
            fromDate:  date
        });
    }

    setToDate(date) {

        this.setState({
            toDate:  date
        });
    }






    handleSubmit(e) {
        e.preventDefault();

        const {
            vin,
            info,
            transport,
            portOfOrigin,
            transshipmentPort,
            portOfDischarging,
            destination,
            bl,
            voyage,
            fromDate,
            toDate,
            make,
            model,
            transportCompany,
            clientComment,
            inspectusComment,
            inspectedBy,
            transportMode,

            damageSetPart,
            damageSetSeverity,
            damageSetType,
            damageSetCause,
            damageSetRepairMode,
            damageSetClass,
            damageSetDln,

            inspected,
            uninspected,
            damaged,
            undamaged,
            images,
            reports,
            perPage
        } = this.state;

        this.props.updateItems({
            transportCompany,
            vin,
            info,
            transport,
            portOfOrigin,
            transshipmentPort,
            portOfDischarging,
            destination,
            bl,
            voyage,
            fromDate,
            toDate,
            make,
            model,
            clientComment,
            inspectusComment,
            inspectedBy,
            transportMode,
            damageSetPart,
            damageSetSeverity,
            damageSetType,
            damageSetCause,
            damageSetRepairMode,
            damageSetClass,
            damageSetDln,

            inspected,
            uninspected,
            damaged,
            undamaged,
            images,
            reports,
            perPage
        })
            .then(() => {
                this.props.handleScrollToElement();
            });
    }


    clearFilters() {
        this.setState({
            vin: '',
            info: '',
            transportCompany: '',
            transport: '',
            portOfOrigin: '',
            transshipmentPort: '',
            portOfDischarging: '',
            destination: '',
            bl: '',
            voyage: '',
            transportMode: '',
            toDate: '',
            fromDate: '',
            make: '',
            model: '',
            clientComment: '',
            inspectusComment: '',
            inspectedBy: '',

            damageSetPart: '',
            damageSetSeverity: '',
            damageSetType: '',
            damageSetCause: '',
            damageSetRepairMode: '',
            damageSetClass: '',
            damageSetDln: '',

            inspected: false,
            uninspected: false,
            damaged: false,
            undamaged: false,
            images: false,
            reports: false,
            clearFiltersClicked: true
        });

        this.props.updateItems();
    }

    handleDamageSetChange(name, value) {
        this.setState({
            [name]: value
        })
    }

    handleInputChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });
    }

    handleSelectChange(obj, e) {
        console.log(e);

        this.setState({
            [e.name]: obj
        })
    }

    handlePerPageChange(obj, e) {

        this.setState({
            [obj.target.name]: obj.target.value
        })
    }

    handleInspectedInputChange(e) {
        if(e.target.name === 'inspected') {
            if(!this.state.inspected) {
                this.setState({
                    inspected: true,
                    uninspected: false
                })
            } else {
                this.setState({
                    inspected: false,
                    uninspected: false
                })
            }
        }

        if(e.target.name === 'uninspected') {
            if(!this.state.uninspected) {
                this.setState({
                    inspected: false,
                    uninspected: true
                })
            } else {
                this.setState({
                    inspected: false,
                    uninspected: false
                })
            }
        }
    }

    handleDamagedInputChange(e) {
        if(e.target.name === 'damaged') {
            if(!this.state.damaged) {
                this.setState({
                    damaged: true,
                    undamaged: false
                })
            } else {
                this.setState({
                    damaged: false,
                    undamaged: false
                })
            }
        }

        if(e.target.name === 'undamaged') {
            if(!this.state.undamaged) {
                this.setState({
                    damaged: false,
                    undamaged: true
                })
            } else {
                this.setState({
                    damaged: false,
                    undamaged: false
                })
            }
        }
    }

    render() {
        return (
            <div>
                <form action="" onSubmit={this.handleSubmit}>
                    <div className="row mb-4">
                        <h2 className={"mb-3"}>Inspection Date Range</h2>
                        <div className={Style.dateField + ' col-auto mb-3'}>
                            <label htmlFor="inspection-date-from">From date</label>
                            <DatePicker className={'form-control'} dateFormat="dd.MM.yyyy" selected={this.state.fromDate} onChange={(date) => this.setFromDate(date)}/>
                        </div>
                        <div className={Style.dateField + ' col-auto mb-3'}>
                            <label htmlFor="inspection-date-to">To date</label>
                            <DatePicker className={'form-control'} dateFormat="dd.MM.yyyy" selected={this.state.toDate} onChange={(date) => this.setToDate(date)} />
                        </div>
                    </div>
                    <div className="row mb-4">
                        <h2 className={"mb-3"}>Cargo Data</h2>
                        <div className="col-auto mb-3">
                            <label htmlFor="vin">VIN</label>
                            <input type="text" className={"form-control"} name="vin" id={"vin"} onChange={this.handleChange} value={this.state.vin}/>
                        </div>
                        <div className="col-auto mb-3">
                            <label htmlFor="info">Info</label>
                            <input type="text" className={"form-control"} name="info" id={"info"} onChange={this.handleChange} value={this.state.info}/>
                        </div>
{/*                         <div className="col-auto mb-3">
                            <label htmlFor="transport-company">Transport Company</label>
                            <BaseSelect
                                closeMenuOnSelect={true}
                                isMulti
                                options={this.state.transportCompanyOptions}
                                name="transportCompany"
                                value={this.state.transportCompany}
                                className="basic-multi-select filter-multi-select"
                                classNamePrefix="select"
                                onChange={this.handleSelectChange}
                            />
                        </div> */}
                        <div className="col-auto mb-3">
                            <label htmlFor="bl">BL</label>
                            <BaseSelect
                                closeMenuOnSelect={true}
                                isMulti
                                options={this.state.blOptions}
                                name="bl"
                                value={this.state.bl}
                                className="basic-multi-select filter-multi-select"
                                classNamePrefix="select"
                                onChange={this.handleSelectChange}
                            />
                        </div>
                        <div className="col-auto mb-3">
                            <ModelFilter
                                label={"Make"}
                                elementName={"make"}
                                elementId={"make-id"}
                                value={this.state.make}
                                handleChange={this.handleSelectChange}
                                items={this.state.makesArr}
                                idField={"id"}
                                labelField={"name"}
                            />
                        </div>
                        <div className="col-auto mb-3">
                            <ModelFilter
                                label={"Model"}
                                elementName={"model"}
                                elementId={"model-id"}
                                value={this.state.model}
                                handleChange={this.handleSelectChange}
                                items={this.state.modelsArr}
                                idField={"id"}
                                labelField={"name"}
                            />
                        </div>
                        <div className="col-auto mb-3">
                            <ModelFilter
                                isMulti
                                label={"Port of Origin"}
                                elementName={"portOfOrigin"}
                                elementId={"portoforigin-id"}
                                value={this.state.portOfOrigin}
                                handleChange={this.handleSelectChange}
                                items={this.state.portsOfOriginArr}
                                idField={"id"}
                                labelField={"value"}
                            />
                        </div>
                        <div className="col-auto mb-3">
                            <ModelFilter
                                isMulti
                                label={"Transshipment Port"}
                                elementName={"transshipmentPort"}
                                elementId={"transshipmentport-id"}
                                value={this.state.transshipmentPort}
                                handleChange={this.handleSelectChange}
                                items={this.state.transshipmentPortsArr}
                                idField={"id"}
                                labelField={"value"}
                            />
                        </div>

                        <div className="col-auto mb-3">

                            <ModelFilter
                                isMulti
                                label={"Port of discharging"}
                                elementName={"portOfDischarging"}
                                elementId={"portofdischarging-id"}
                                value={this.state.portOfDischarging}
                                handleChange={this.handleSelectChange}
                                items={this.state.portOfDischargingArr}
                                idField={"id"}
                                labelField={"value"}
                            />
                        </div>
                        <div className="col-auto mb-3">
                            <label htmlFor="destination">Destination</label>
                            <BaseSelect
                                closeMenuOnSelect={true}
                                isMulti
                                options={this.state.countriesOptions}
                                name="destination"
                                value={this.state.destination}
                                className="basic-multi-select filter-multi-select"
                                classNamePrefix="select"
                                onChange={this.handleSelectChange}
                            />
                        </div>
                        <div className="col-auto mb-3">
                            <label htmlFor="destination">Voyage</label>
                            <BaseSelect
                                closeMenuOnSelect={true}
                                isMulti
                                options={this.state.voyageOptions}
                                name="voyage"
                                value={this.state.voyage}
                                className="basic-multi-select filter-multi-select"
                                classNamePrefix="select"
                                onChange={this.handleSelectChange}
                            />
                        </div>
                        <div className="col-auto mb-3">
                            <label htmlFor="destination">Transport ID</label>
                            <BaseSelect
                                closeMenuOnSelect={true}
                                isMulti
                                options={this.state.transportModes}
                                name="transportMode"
                                value={this.state.transportMode}
                                className="basic-multi-select filter-multi-select"
                                classNamePrefix="select"
                                onChange={this.handleSelectChange}
                            />
                        </div>
                        <div className="col-auto mb-3">
                            <ModelFilter
                                label={"Transport mode"}
                                elementName="transport"
                                elementId={"transport-id"}
                                value={this.state.transport}
                                handleChange={this.handleSelectChange}
                                items={this.state.transportsArr}
                                idField={"id"}
                                labelField={"identificationName"}
                            />
                        </div>

                        <div className="row">
                            <div className="col-auto mb-3">
                                <span className="d-block">Inspection</span>
                                <div className="mt-1">
                                    <div className="form-check form-check-inline">
                                        <input
                                            className="form-check-input"
                                            type="checkbox"
                                            value=""
                                            id="test"
                                            name="inspected"
                                            checked={this.state.inspected}
                                            onChange={this.handleInspectedInputChange}
                                        />
                                        <label className="form-check-label">
                                            Inspected
                                        </label>
                                    </div>
                                    <div className="form-check form-check-inline">
                                        <input
                                            className="form-check-input"
                                            type="checkbox"
                                            value=""
                                            id="statusInspection"
                                            name="uninspected"
                                            checked={this.state.uninspected}
                                            onChange={this.handleInspectedInputChange}
                                        />
                                        <label className="form-check-label" htmlFor="statusDamaged">
                                            Uninspected
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div className="col-auto mb-3">
                                <span className="d-block">Damage</span>
                                <div className="mt-1">
                                    <div className="form-check form-check-inline">
                                        <input
                                            className="form-check-input"
                                            type="checkbox"
                                            value=""
                                            id="damaged"
                                            name="damaged"
                                            checked={this.state.damaged}
                                            onChange={this.handleDamagedInputChange}
                                        />
                                        <label className="form-check-label">
                                            Damaged
                                        </label>
                                    </div>
                                    <div className="form-check form-check-inline">
                                        <input
                                            className="form-check-input"
                                            type="checkbox"
                                            value=""
                                            id="undamaged"
                                            name="undamaged"
                                            checked={this.state.undamaged}
                                            onChange={this.handleDamagedInputChange}
                                        />
                                        <label className="form-check-label" htmlFor="statusDamaged">
                                            Undamaged
                                        </label>
                                    </div>
                                </div>
                            </div>
                            {/* <div className="col-auto mb-3">
                                <span className="d-block">Status</span>
                                <div className="mt-1">
                                    <div className="form-check form-check-inline">
                                        <input
                                            className="form-check-input"
                                            type="checkbox"
                                            value=""
                                            id="statusInspected"
                                            name="inspectedd"
                                            checked={this.state.damaged}
                                            onChange={this.handleInputChange}
                                        />
                                        <label className="form-check-label" htmlFor="statusInspected">
                                            test
                                        </label>
                                    </div>
                                    <div className="form-check form-check-inline">
                                        <input
                                            className="form-check-input"
                                            type="checkbox"
                                            value=""
                                            id="statusDamaged"
                                            name="damaged"
                                            checked={this.state.damaged}
                                            onChange={this.handleInputChange}
                                        />
                                        <label className="form-check-label" htmlFor="statusDamaged">
                                            Damaged
                                        </label>
                                    </div>
                                </div>
                            </div> */}
                        </div>



                        <div className="col-auto mb-3">
                            <span className="d-block">Files</span>
                            <div className="mt-1">
                                <div className="form-check form-check-inline">
                                    <input
                                        className="form-check-input"
                                        type="checkbox"
                                        value=""
                                        id="filesImages"
                                        name="images"
                                        checked={this.state.images}
                                        onChange={this.handleInputChange}
                                    />
                                    <label className="form-check-label" htmlFor="filesImages">
                                        General Images
                                    </label>
                                </div>
                                <div className="form-check form-check-inline">
                                    <input
                                        className="form-check-input"
                                        type="checkbox"
                                        value=""
                                        id="filesReports"
                                        name="reports"
                                        checked={this.state.reports}
                                        onChange={this.handleInputChange}
                                    />
                                    <label className="form-check-label" htmlFor="filesReports">
                                        Report
                                    </label>
                                </div>
                            </div>
                        </div>

                    </div>
                    <div className="row">
                        <h2 className={"mb-3"}>Damages</h2>
                        <div className="col-auto mb-3">
                            <label htmlFor="clientComment">Client Comment</label>
                            <input type="text" className={"form-control"} name="clientComment" id={"clientComment"} onChange={this.handleChange} value={this.state.clientComment}/>
                        </div>
                        {/* <div className="col-auto mb-3">
                            <label htmlFor="inspectusComment">Inspectus Comment</label>
                            <BaseSelect
                            closeMenuOnSelect={true}
                            isMulti
                            options={this.state.inspectusComments}
                            name="inspectusComment"
                            value={this.state.inspectusComment}
                            className="basic-multi-select filter-multi-select"
                            classNamePrefix="select"
                            onChange={this.handleSelectChange}
                            />
                        </div> */}

                        <div className="col-auto mb-3">
                            <label htmlFor="inspectedBy">Inspected By</label>
                            <BaseSelect
                                closeMenuOnSelect={true}
                                isMulti
                                options={this.state.inspectors}
                                name="inspectedBy"
                                value={this.state.inspectedBy}
                                className="basic-multi-select filter-multi-select"
                                classNamePrefix="select"
                                onChange={this.handleSelectChange}
                            />
                        </div>

                        <div className="col-auto mb-3">
                            <label htmlFor="damageSetPart">Damage Set: Part</label>
                            <BaseSelect
                                closeMenuOnSelect={true}
                                isMulti
                                options={this.state.dsPartOptions}
                                name="damageSetPart"
                                value={this.state.damageSetPart}
                                className="basic-multi-select filter-multi-select"
                                classNamePrefix="select"
                                onChange={this.handleSelectChange}
                            />
                        </div>

                        <div className="col-auto mb-3">
                            <label htmlFor="damageSetType">Damage Set: Type</label>
                            <BaseSelect
                                closeMenuOnSelect={true}
                                isMulti
                                options={this.state.dsTypeOptions}
                                name="damageSetType"
                                value={this.state.damageSetType}
                                className="basic-multi-select filter-multi-select"
                                classNamePrefix="select"
                                onChange={this.handleSelectChange}
                            />
                        </div>

                        <div className="col-auto mb-3">
                            <label htmlFor="damageSetSeverity">Damage Set: Severity</label>
                            <BaseSelect
                                closeMenuOnSelect={true}
                                isMulti
                                options={this.state.dsSeverityOptions}
                                name="damageSetSeverity"
                                value={this.state.damageSetSeverity}
                                className="basic-multi-select filter-multi-select"
                                classNamePrefix="select"
                                onChange={this.handleSelectChange}
                            />
                        </div>

                        <div className="col-auto mb-3">
                            <label htmlFor="damageSetCause">Damage Set: Cause</label>
                            <BaseSelect
                                closeMenuOnSelect={true}
                                isMulti
                                options={this.state.dsCauseOptions}
                                name="damageSetCause"
                                value={this.state.damageSetCause}
                                className="basic-multi-select filter-multi-select"
                                classNamePrefix="select"
                                onChange={this.handleSelectChange}
                            />
                        </div>

                        <div className="col-auto mb-3">
                            <label htmlFor="damageSetRepairMode">Damage Set: Repair Mode</label>
                            <BaseSelect
                                closeMenuOnSelect={true}
                                isMulti
                                options={this.state.dsRepairModeOptions}
                                name="damageSetRepairMode"
                                value={this.state.damageSetRepairMode}
                                className="basic-multi-select filter-multi-select"
                                classNamePrefix="select"
                                onChange={this.handleSelectChange}
                            />
                        </div>

                        <div className="col-auto mb-3">
                            <label htmlFor="damageSetClass">Damage Set: Class</label>
                            <BaseSelect
                                closeMenuOnSelect={true}
                                isMulti
                                options={this.state.dsClassOptions}
                                name="damageSetClass"
                                value={this.state.damageSetClass}
                                className="basic-multi-select filter-multi-select"
                                classNamePrefix="select"
                                onChange={this.handleSelectChange}
                            />
                        </div>

                        <div className="col-auto mb-3">
                            <label htmlFor="damageSetDln">Damage Set: DLN</label>
                            <BaseSelect
                                closeMenuOnSelect={true}
                                isMulti
                                options={this.state.dsDlnOptions}
                                name="damageSetDln"
                                value={this.state.damageSetDln}
                                className="basic-multi-select filter-multi-select"
                                classNamePrefix="select"
                                onChange={this.handleSelectChange}
                            />
                        </div>

                        <div className="col-auto mb-3">
                        <div className={"d-flex align-items-center mx-4"}>
                            <strong className={"me-3 nowrap"}>No. of items</strong>
                            <select className={"form-select me-2"} name="perPage" onChange={this.handlePerPageChange}>
                                <option value="15">15</option>
                                <option value="100">100</option>
                                <option value="200">200</option>
                                <option value="-1">ALL</option>
                            </select>
                        </div>
                        </div>




                    </div>
                    <div className="row">
                        <div className="col-12 d-flex align-items-center">
                            <button type={"submit"} className={"btn btn-primary"}>Filter</button>
                            <button onClick={this.clearFilters} className={"btn-min ms-3"}>Clear filters</button>
                        </div>
                    </div>
                </form>
            </div>
        );
    }
}

Filters.propTypes = {};

export default Filters;
