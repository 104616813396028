import React from "react";
import {Link} from "react-router-dom";
import {getAdmins, getClients, getDamageSets, getInspectors, getManagers, getTransports, getPorts, getTransportCompanies} from "../../../../utils/utils";
import BaseSelect from 'react-select';
import Axios from "axios";
import Store from "store";
import moment from "moment";
import inspectionTypes from "../../../../fixedData/inspectionTypes";
import {FileUploader} from "../../../../FileUploader/FileUploader";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import FixRequiredSelect from "../../../../utils/FixRequiredSelect";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import 'react-datepicker/dist/react-datepicker-cssmodules.css';

const SuccessModal = withReactContent(Swal);
const ErrorModal = withReactContent(Swal);

// const Select = props => (
//     <FixRequiredSelect
//         {...props}
//         SelectComponent={BaseSelect}
//         options={props.options || options}
//     />
// );

class AddNewOrder extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            'owner': null, //client
            token: Store.get('accessToken').token,
            'inspectors': [],
            'admin': null,
            'client': null,
            'notes': '',
            'adminsArr': [],
            'inspectorsArr': [],
            'clientsArr': [],
            'transportsArr': [],
            dischargingDate: '',
            arrivalDate: '',
            loadingDate: '',
            departureDate: '',
            referenceNumberInspectus: '',
            referenceNumberClient: '',
            damageSetsArr: [],
            damageSetId: null,
            transportType: '',
            gate: '',
            inspectionType: '',
            transports: [],
            inspectionSitesArr: [],
            transportCompaniesArr: [],
            'transportCompany': '',
            'inspectionSite': '',
            'cargo_type': '',
            'jointSurvey': false,
            'receivingParty': false,
            'sendingParty': false,
            transportsDisabled: true,
            surveyStart: '',
            surveyEnd: '',
            surveyStartTime: '',
            surveyEndTime: '',
            loadingDateExport: '',
            shortcuts: [],
            selectedShortcuts: [],
            'isRidOrderType': false,
        };

        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleSelectChange = this.handleSelectChange.bind(this);
        this.handleMultiSelectChange = this.handleMultiSelectChange.bind(this);
        this.setSelectedFile = this.setSelectedFile.bind(this);
        this.jointSurveyHandleChange = this.jointSurveyHandleChange.bind(this);
        this.receivingPartyHandleChange = this.receivingPartyHandleChange.bind(this);
        this.radioSelected = this.radioSelected.bind(this);
        this.setSurveyStart = this.setSurveyStart.bind(this);
        this.setSurveyEnd = this.setSurveyEnd.bind(this);
        this.setLoadingDateExport = this.setLoadingDateExport.bind(this);
        this.handleSelectDamageSetChange = this.handleSelectDamageSetChange.bind(this);

    }

    handleSubmit(e) {
        e.preventDefault();

        SuccessModal.showLoading();
        
        Axios.post(process.env.REACT_APP_API_BASE + '/orders', {
            'admin_id': this.state.admin,
            'client_id': this.state.client,
            'inspectors': this.state.inspectors,
            'items': [],
            'orderNotes': this.state.notes,
            'damageSetId': this.state.damageSetId,
            'transportType': this.state.transportType,
            'gate': this.state.gate,
            'inspectionType':this.state.inspectionType,
            'inspectionSite': this.state.inspectionSite,
            'transportIds': this.state.transports,
            'cargo_type': this.state.cargo_type,
            'jointSurvey': this.state.jointSurvey,
            'receivingParty': this.state.receivingParty,
            'sendingParty': this.state.sendingParty,
            'referenceNumberInspectus': this.state.referenceNumberInspectus,
            'referenceNumberClient': this.state.referenceNumberClient,
            'arrivalDate': this.state.arrivalDate ? moment(this.state.arrivalDate).format("YYYY-MM-DD") : "",
            'departureDate': this.state.departureDate ? moment(this.state.departureDate).format("YYYY-MM-DD") : "",
            'surveyStart': this.state.surveyStart ? moment(this.state.surveyStart).format("YYYY-MM-DD") : "",
            'surveyEnd': this.state.surveyEnd ? moment(this.state.surveyEnd).format("YYYY-MM-DD") : "",
            'surveyStartTime': this.state.surveyStartTime,
            'surveyEndTime': this.state.surveyEndTime,
            'loadingDateExport': this.state.loadingDateExport ? moment(this.state.loadingDateExport).format("YYYY-MM-DD") : "",
            'transportCompany': this.state.transportCompany,
            'shortcuts': this.state.selectedShortcuts,
            'is_rid_type': this.state.isRidOrderType
        }, {
            headers: {
                'Authorization': 'Bearer ' + this.state.token
            }
        } )
            .then(response => {

                // return;
                if (response.status === 201) {
                    this.setState({
                        id: response.data.order.id
                    }, () => {
                        if (this.state.images) {
                            this.addImages(this.state.images).then(r => {
                                SuccessModal.fire({
                                    title: 'Successfully added new order',
                                }).then(() => {
                                    this.props.history.push('/dashboard/orders/order/'+this.state.id);
                                });
                            });
                        } else {
                            SuccessModal.fire({
                                title: 'Successfully added new order',
                            }).then(() => {
                                this.props.history.push('/dashboard/orders/order/'+this.state.id);
                            });
                        }
                    });

                }
            })
            .catch(error => {
                console.log(error);
                SuccessModal.fire({
                    title: 'Something went wrong',
                    icon: 'error'
                }).then(() => {
                    this.props.history.push('/dashboard/orders');
                });
                this.setState({
                    'formErrors': error.response.data.message
                })
            })
    }

    setArrivalDate(date) {
        this.setState({
            arrivalDate:  date
        });
    }

    setSurveyStart(date) {
        this.setState({
            surveyStart:  date
        });
    }

    setLoadingDateExport(date) {
        this.setState({
            loadingDateExport:  date
        });
    }

    setSurveyEnd(date) {
        this.setState({
            surveyEnd:  date
        });
    }

    setDischargingDate(date) {
        this.setState({
            dischargingDate:  date
        });
    }

    setLoadingDate(date) {
        this.setState({
            loadingDate:  date
        });
    }

    setDepartureDate(date) {
        this.setState({
            departureDate:  date
        });
    }

    handleChange(e) {
        this.setState({
            [e.target.name]: e.target.value
        }, () => {
            if(e.target.name === 'transportType') {
                if(e.target.value === 'vessel') {
                    this.getTransportData();
                }

            }
        });



        if (e.target.name === 'gate') {
            this.setState({
                inspectionType: ''
            });
        }
    }

    handleSelectChange(obj, e) {

        this.setState({
            [e.name]: obj.value
        })
    }

    handleSelectDamageSetChange(obj, e) {

        this.setState({
            [e.name]: obj.value
        })
    
    }

    handleMultiSelectChange(obj, e) {

        let itemsIdArr = [];

        for (let item of obj) {
            itemsIdArr.push(item.value);
        }

        this.setState({
            [e.name]: itemsIdArr
        })
    }

    jointSurveyHandleChange(e) {
        if(e.target.value === 'true') {
            this.setState({
                jointSurvey: true
            })
        } else {
            this.setState({
                jointSurvey: false
            })
        }
    }

    receivingPartyHandleChange(e) {
        if(e.target.value === 'true') {
            this.setState({
                receivingParty: true
            })
        } else {
            this.setState({
                receivingParty: false
            })
        }
    }

    getTransportData() {
        this.setState({
            transportsDisabled: false
        })
        Axios.get(process.env.REACT_APP_API_BASE + '/transports', {
            params: {
                transport_mode: this.state.transportType
            },
            headers: {
                'Authorization': 'Bearer ' + this.state.token
            }
        })
            .then(res => {
                let transportData = res.data.transports;
                let transportArr = [];
                transportData.forEach(transport => {
                    transportArr.push({
                        'value': transport.id,
                        'label': transport.identificationName
                    });
                })

                transportArr.sort((a, b) => a.label > b.label ? 1 : -1)

                this.setState({
                    'transportsArr': transportArr
                })
            })

    };


    componentDidMount() {
        let tempAdmins = [];
        let inspectionSitesArr = [];
        let transportCompaniesArr = [];

        getManagers().then(resp => {
            console.log(resp);

            for (const item of resp) {
                tempAdmins.push({
                    'value': item.id,
                    'label': item.name + ' (' + item.email + ')'
                });
            }

            this.setState({
                'adminsArr': tempAdmins
            });
        });

        getTransportCompanies()
            .then(resp => {
                for (const item of resp) {
                    transportCompaniesArr.push({
                        'value': item.id,
                        'label': item.name + ' (' + item.code + ')'
                    });
                }
            }).then(() => {
                this.setState({
                    'transportCompaniesArr': transportCompaniesArr
                });
            });

        getPorts()
            .then(resp => {
                for (const item of resp) {
                    inspectionSitesArr.push({
                        'value': item.id,
                        'label': item.name + ' (' + item.code + ')'
                    });
                }
            }).then(() => {
                this.setState({
                    'inspectionSitesArr': inspectionSitesArr
                });
            });


        var tempInspectors = [];
        getInspectors().then(resp => {

            for (const item of resp) {
                tempInspectors.push({
                    'value': item.id,
                    'label': item.name + ' (' + item.email + ')'
                });
            }
        }).then(() => {
            this.setState({
                'inspectorsArr': tempInspectors
            });
        });


        var tempClients = [];
        getClients().then(resp => {

            for (const item of resp) {
                tempClients.push({
                    'value': item.id,
                    'label': item.name + ' (' + item.email + ')'
                });
            }
        }).then(() => {
            this.setState({
                'clientsArr': tempClients
            });
        });

        let tempDamageSets = [];
        getDamageSets()
            .then(resp => {
                for (const item of resp) {
                    tempDamageSets.push({
                        'value': item.id,
                        'label': item.name
                    });
                }
            })
            .then(() => {
                this.setState({
                    damageSetsArr: tempDamageSets
                });
            });

        /*let tempTransports = [];
        getTransports()
            .then(resp => {
                for (const item of resp) {
                    tempTransports.push({
                        'value': item.id,
                        'label': item.identificationName + ' (' + item.transportDetailsId + ')'
                    });
                }
            })
            .then(() => {
            this.setState({
                'transportsArr': tempTransports
            });
        });*/
    }


    inspectionTypeSelect() {
        if (inspectionTypes) {
            if (this.state.gate) {
                return (
                    <select className={"form-select"} name={"inspectionType"} id={"inspectionType"} value={this.state.inspectionType} onChange={this.handleChange}>
                        <option value="">Select Inspection Type</option>
                        {inspectionTypes[this.state.gate].map((item, index) => {
                            return (
                                <option value={item}>{item}</option>
                            );
                        })}
                    </select>
                )
            } else {
                return (
                    <input type="text" placeholder={"Select gate first"} disabled className={"form-control"}/>
                )
            }
        }
    }

    addImages(images) {

        return new Promise((resolve, reject) => {
            if (!images) throw new Error('Missing images');

            let formData = new FormData();

            for (let i = 0; i < images.length; i++) {
                formData.append('image[]', images[i]);
            }
            formData.append('order_id', this.state.id);

            Axios.post(process.env.REACT_APP_API_BASE + '/orderimages', formData, {
                headers: {
                    'Authorization': 'Bearer ' + this.state.token
                }
            } )
                .then(response => {
                    if (response.status === 200) {
                        resolve();
                    }
                })
                .catch(error => {
                    console.log(error);
                    this.setState({
                        'formErrors': error.response.data.message
                    }, () => {
                        reject(error.response.data.message);
                    });
                })
        });
    }


    setSelectedFile(file) {
        this.setState({
            images: file
        })
    }

    radioSelected(e) {
        let selection = e.target.value;
        if(selection === 'true') {
            this.setState({
                [e.target.name]: true
            })
        } else {
            this.setState({
                [e.target.name]: false
            })
        }
    }

    render() {
        return (
            <div className="container pb-10" id="main">
                <div className="row mb-5">
                    <div className="col-12 col-md-8">
                        <Link to={"/dashboard/orders/"} className={"btn-min"}>
                            <span className="ml-1">View all orders</span>
                        </Link>
                        <h1 className={"mt-1"}>Add New Order</h1>
                    </div>
                </div>

                <div className="row">
                    <div className="col-12 col-md-8">
                        <form action="" className={"mb-5"} onSubmit={this.handleSubmit}>

                            <div class="row mb-3">
                                <div className="col-12 col-md-6">
                                    <div className="form-group">
                                        <label htmlFor="referenceNumberInspectus">Reference number inspectus</label>
                                        <input type="text" placeholder={"12313231"} className={"form-control"} name={"referenceNumberInspectus"} id={"referenceNumberInspectus"} value={this.state.referenceNumberInspectus} onChange={this.handleChange}/>
                                    </div>
                                </div>
                                <div className="col-12 col-md-6">
                                    <div className="form-group">
                                        <label htmlFor="referenceNumberClient">Reference number client</label>
                                        <input type="text" placeholder={"12313231"} className={"form-control"} name={"referenceNumberClient"} id={"referenceNumberClient"} value={this.state.referenceNumberClient} onChange={this.handleChange}/>
                                    </div>
                                </div>
                            </div>

                            <div class="row mb-3">
                                <div className="col-12 col-md-6">
                                    <div className="form-group">
                                        <label htmlFor="arrival-date">Arrival date</label>
                                        <DatePicker className={'form-control'} dateFormat="dd.MM.yyyy" selected={this.state.arrivalDate} onChange={(date) => this.setArrivalDate(date)} utcOffset={0}/>
                                    </div>
                                </div>
                                <div className="col-12 col-md-6">
                                    <div className="form-group">
                                        <label htmlFor="discharging-date">Departure date</label>
                                        <DatePicker className={'form-control'} dateFormat="dd.MM.yyyy" selected={this.state.departureDate} onChange={(date) => this.setDepartureDate(date)} utcOffset={0}/>
                                    </div>
                                </div>
                            </div>

                            <div className="row mb-3">
                                <div className="col-12 col-md-6">
                                    <div className="form-group">
                                        <label htmlFor="arrival-date">Survey start</label>
                                        <DatePicker className={'form-control'} dateFormat="dd.MM.yyyy"
                                                    selected={this.state.surveyStart}
                                                    utcOffset={0}
                                                    onChange={(date) => this.setSurveyStart(date)}/>
                                    </div>
                                </div>
                                <div className="col-12 col-md-6">
                                    <div className="form-group">
                                        <label htmlFor="discharging-date">Survey end</label>
                                        <DatePicker className={'form-control'} dateFormat="dd.MM.yyyy"
                                                    selected={this.state.surveyEnd}
                                                    utcOffset={0}
                                                    onChange={(date) => this.setSurveyEnd(date)}/>
                                    </div>
                                </div>
                            </div>

                            <div class="row mb-3">
                                <div className="col-12 col-md-6">
                                    <div className="form-group">
                                        <label htmlFor="referenceNumberInspectus">Survey start time:</label>
                                        <input type="text" placeholder={"10:00"} className={"form-control"} name={"surveyStartTime"} id={"surveyStartTime"} value={this.state.surveyStartTime} onChange={this.handleChange}/>
                                    </div>
                                </div>
                                <div className="col-12 col-md-6">
                                    <div className="form-group">
                                        <label htmlFor="referenceNumberClient">Survey end time:</label>
                                        <input type="text" placeholder={"15:00"} className={"form-control"} name={"surveyEndTime"} id={"surveyEndTime"} value={this.state.surveyEndTime} onChange={this.handleChange}/>
                                    </div>
                                </div>
                            </div>

                            <div className="row mb-3">
                                <div className="col-12 col-md-6">
                                    <div className="form-group">
                                        <label htmlFor="arrival-date">Loading date export</label>
                                        <DatePicker className={'form-control'} dateFormat="dd.MM.yyyy"
                                                    selected={this.state.loadingDateExport}
                                                    utcOffset={0}
                                                    onChange={(date) => this.setLoadingDateExport(date)}/>
                                    </div>
                                </div>
                            </div>

                            <div class="row mb-3">
                                <div className="col-12 col-md-6">
                                    <div className="form-group">
                                        <label htmlFor="arrival-date">Site of inspection*</label>
                                        <FixRequiredSelect
                                            SelectComponent={BaseSelect}
                                            closeMenuOnSelect={true}
                                            options={this.state.inspectionSitesArr}
                                            onChange={this.handleSelectChange}
                                            name={"inspectionSite"}
                                            required
                                        />
                                    </div>
                                </div>
                                <div className="col-12 col-md-6">
                                    <div className="form-group">
                                        <label htmlFor="arrival-date">Transport company*</label>
                                        <FixRequiredSelect
                                            SelectComponent={BaseSelect}
                                            closeMenuOnSelect={true}
                                            options={this.state.transportCompaniesArr}
                                            onChange={this.handleSelectChange}
                                            name={"transportCompany"}
                                            required
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className="row mb-3">
                                <div className="col-12 col-md-6">
                                    <div className="form-group">
                                        <label htmlFor="transportType">Transport Type*</label>
                                        <select className={"form-select"} name={"transportType"} id={"transportType"} value={this.state.transportType} onChange={this.handleChange}>
                                            <option disabled value="">Select Transport Type</option>
                                            <option value="train">Train</option>
                                            <option value="truck">Truck</option>
                                            <option value="vessel">Vessel</option>
                                            <option value="barge">Barge</option>
                                            <option value="compound">Compound</option>
                                            <option value="factory">Factory</option>
                                            <option value="container">Container</option>
                                            <option value="shuttle">Shuttle</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="col-12 col-md-6">
                                    <div className="form-group">
                                        <label htmlFor="client">Client*</label>
                                        <FixRequiredSelect
                                            SelectComponent={BaseSelect}
                                            closeMenuOnSelect={true}
                                            options={this.state.clientsArr}
                                            onChange={this.handleSelectChange}
                                            name={"client"}
                                            required
                                        />
                                    </div>
                                </div>
                            </div>


                            <div className="row mb-3">
                                <div className="col-12 col-md-6">
                                    <div className="form-group">
                                        <label htmlFor="owner">Order Manager*</label>
                                        <FixRequiredSelect
                                            SelectComponent={BaseSelect}
                                            closeMenuOnSelect={true}
                                            options={this.state.adminsArr}
                                            onChange={this.handleSelectChange}
                                            name={"admin"}
                                            required
                                        />
                                    </div>
                                </div>
                                <div className="col-12 col-md-6">
                                    <div className="form-group">
                                        <label htmlFor="name">Inspectors</label>
                                        <FixRequiredSelect
                                            SelectComponent={BaseSelect}
                                            closeMenuOnSelect={true}
                                            isMulti
                                            name={"inspectors"}
                                            options={this.state.inspectorsArr}
                                            onChange={this.handleMultiSelectChange}
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-12 col-md-6">
                                    <div className="form-group">
                                        <label htmlFor="name">Damage Set</label>
                                        <BaseSelect
                                            closeMenuOnSelect={true}
                                            options={this.state.damageSetsArr}
                                            name="damageSetId"
                                            className="basic-multi-select"
                                            classNamePrefix="select"
                                            onChange={this.handleSelectDamageSetChange}
                                        />
                                    </div>
                                </div>
                                {/*<div className="col-12 col-md-6">
                                    <div className="form-group">
                                        <label htmlFor="name">Damage Set Shortcuts</label>
                                        <BaseSelect
                                                closeMenuOnSelect={true}
                                                isMulti
                                                options={this.state.shortcuts}
                                                onChange={this.handleMultiSelectChange}
                                                name={"selectedShortcuts"}
                                        />
                                    </div>
                                    </div>*/}
                            </div>
                            <div className="row mt-3">
                                <div className="col-12 col-md-6">
                                    <div className="form-group">
                                        <label htmlFor="gate">Gate</label>
                                        <select className={"form-select"} name={"gate"} id={"gate"} value={this.state.gate} onChange={this.handleChange}>
                                            <option disabled value="">Select gate</option>
                                            <option value="arrival">Arrival</option>
                                            <option value="departure">Departure</option>
                                            <option value="internal">Internal</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="col-12 col-md-6">
                                    <div className="form-group">
                                        <label htmlFor="inspectionType">Inspection Type</label>
                                        {this.inspectionTypeSelect()}
                                    </div>
                                </div>
                            </div>
                            <div className="row mt-3">
                                {this.state.transportType === 'vessel' ?
                                    <div className="col-12 col-md-6">
                                        <div className="form-group">
                                            <label htmlFor="transports">Transports</label>
                                            <BaseSelect
                                                closeMenuOnSelect={true}
                                                isMulti
                                                options={this.state.transportsArr}
                                                onChange={this.handleMultiSelectChange}
                                                isDisabled={this.state.transportsDisabled}
                                                name={"transports"}
                                            />
                                        </div>
                                    </div>
                                : ''}

                                <div className="col-12 col-md-6">
                                    <div className="form-group">
                                        <label htmlFor="cargo_type">Cargo Type*</label>
                                        <select required className={"form-select"} name={"cargo_type"} id={"cargo_type"} value={this.state.cargo_type} onChange={this.handleChange}>
                                            <option value="">Select Cargo Type</option>
                                            <option value="vehicles">Vehicles</option>
                                            <option value="coils">Coils</option>
                                            <option value="container">Container</option>
                                            <option value="ingo">Ingo</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div className="row mt-3">
                                <div className="col-12 col-md-6">
                                    <div className="form-group">
                                        <h6>Joint survey</h6>
                                        <div className="form-check">
                                            <input onChange={this.radioSelected} value="true" className="form-check-input" checked={this.state.jointSurvey === true} type="radio" name="jointSurvey"
                                                   id="flexRadioDefault1">
                                            </input>Yes
                                        </div>
                                        <div className="form-check">
                                            <input onChange={this.radioSelected} value="false" className="form-check-input" checked={this.state.jointSurvey === false} type="radio" name="jointSurvey"
                                                   id="flexRadioDefault2">
                                            </input>No
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 col-md-6">
                                    <div className="form-group">
                                        <h6>Receiving party</h6>
                                        <div className="form-check">
                                            <input onChange={this.radioSelected} value="true" className="form-check-input" checked={this.state.receivingParty === true} type="radio" name="receivingParty"
                                                   id="flexRadioDefault1">
                                            </input>Yes
                                        </div>
                                        <div className="form-check">
                                            <input onChange={this.radioSelected} value="false" className="form-check-input" checked={this.state.receivingParty === false} type="radio" name="receivingParty"
                                                   id="flexRadioDefault2">
                                            </input>No
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row mt-3">
                                <div className="col-12 col-md-6">
                                    <div className="form-group">
                                        <h6>Sending party</h6>
                                        <div className="form-check">
                                            <input onChange={this.radioSelected} value="true" className="form-check-input" checked={this.state.sendingParty === true} type="radio" name="sendingParty"
                                                   id="flexRadioDefault1">
                                            </input>Yes
                                        </div>
                                        <div className="form-check">
                                            <input onChange={this.radioSelected} value="false" className="form-check-input" checked={this.state.sendingParty === false} type="radio" name="sendingParty"
                                                   id="flexRadioDefault2">
                                            </input>No
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row mt-3">
                                <div className="col-12 col-md-6">
                                    <div className="form-group">
                                        <h6>Is order RID TYPE? (If yes is selected then change damageset to RID)</h6>
                                        <div className="form-check">
                                            <input onChange={this.radioSelected} value="true" className="form-check-input" checked={this.state.isRidOrderType === true} type="radio" name="isRidOrderType"
                                                   id="flexRadioDefault1">
                                            </input>Yes
                                        </div>
                                        <div className="form-check">
                                            <input onChange={this.radioSelected} value="false" className="form-check-input" checked={this.state.isRidOrderType === false} type="radio" name="isRidOrderType"
                                                   id="flexRadioDefault2">
                                            </input>No
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row mt-3">
                                <div className="col-12">
                                    <div className="form-group">
                                        <label htmlFor="notes">Notes</label>
                                        <textarea className={"form-control"} onChange={this.handleChange} name="notes" id="notes" cols="30" rows="5"></textarea>
                                    </div>
                                </div>
                            </div>
                            <div className="row mt-3">
                                <div className="col-12">
                                    <FileUploader
                                        onFileSelect={(file) => this.setSelectedFile(file)}
                                        onFileSelectError={({ error }) => alert(error)}
                                    />
                                </div>
                            </div>



                            <div className="row mt-3">
                                <div className="col-12">
                                    <button type={"submit"} className={"btn btn-primary"}>Submit</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        );
    }
}

AddNewOrder.propTypes = {};

export default AddNewOrder;
