import Style from "./Damage.module.scss";
import React from "react";
import Axios from "axios";

import importedDamageIcon from './../../../../../../../assets/icons/import_icon.svg';
import {ReactComponent as WarningIcon} from './triangle-exclamation-solid.svg';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import Store from "store";
import {displayDate, getCargoItem, getDamage, isAdmin, isNotClient, getImage} from "../../../../../../../utils/utils";
import DamageImages from "./DamageImages/DamageImages";
import DsInput from "./DsInput/DsInput";
import ConditionalContent from "../../../../../../../ConditionalContent";
import BaseSelect from 'react-select';

const SuccessModal = withReactContent(Swal);
const repairModal = withReactContent(Swal);
const UploadImageModal = withReactContent(Swal);
const DeleteItemPopup = withReactContent(Swal);
const LoadingModalSwal = withReactContent(Swal);


class Damage extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            token: Store.get('accessToken').token,
            id: this.props.damageId ? this.props.damageId : null,
            tempId: this.props.tempId ? this.props.tempId : null,
            hashId: this.props.hash_id ? this.props.hash_id : null,
            cargoItemId: this.props.cargoItemId ? this.props.cargoItemId : null,
            orderId: this.props.orderId ? this.props.orderId : null,
            repaired: null,
            images: null,
            dsPart: null,
            dsType: null,
            dsSeverity: null,
            dsCause: null,
            dsRepairMode: null,
            dsClass: null,
            dsDln: null,
            old: null,
            dimensions: '',
            hash_id: null,
            is_imported: null,
            selectedDsPart: null,
            selectedDsType: null,
            selectedDsSeverity: null,
            selectedDsCause: null,
            selectedDsRepairMode: null,
            selectedDsClass: null,
            selectedDsDln: null,
            clientComment: '',
            inspectusComment: '',
            damageImagePaths: [],
            lightboxImages: [],
            ridTypeComments: [
                'The label was removed due to being observed wet on the affixing side.',
                'The label was removed/replaced due to label edges being poorly affixed or already commenced to peel off.',
                'The label was removed/replaced due to being poorly affixed and risk exists for the label to fall off rainy weather conditions or water condesation on the container surface.',
                'The label was removed/replaced due to being damaged.',
                'The service was executed in adverse weather conditions (storm/heavy rain).',
                'At the time of the service, surface of the container was observed stained with oil, which affects the efficency of labeling.',
                'The container was observed with damages on container walls. The condition affects the efficency of labeling.',
                'The container was observed with rust spots on container walls. The condition affects the efficency of labeling.',
                'The labels on the container were indicating dangerous goods, in this respect they have been removed.',
                'The label was removed/replaced due to not being in accordance with ADR standard.',
                'Due to the condition/state of the label it\'s removal could not be complete or performed efficently, without leaving remnants of the label on the container unit.',
                'The lables on the container have been previously poorly resprayed over. Although not visible in picture reports due to reflection, the type previous label was visible with naked eye. / The labels on the container found poorly sprayed over with coloured spray and grease. Although not visible in picture reports due to reflection, the type previous labels were visible with naked eye.'
            ]
        };

        this.handleSubmit = this.handleSubmit.bind(this);
        this.saveData = this.saveData.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.removeNewDamage = this.removeNewDamage.bind(this);
        this.removeExistingDamage = this.removeExistingDamage.bind(this);
        this.markRepaired = this.markRepaired.bind(this);
        this.repairButtonConditional = this.repairButtonConditional.bind(this);
        this.uploadModalTrigger = this.uploadModalTrigger.bind(this);
        this.addDamageImage = this.addDamageImage.bind(this);
        this.refreshImages = this.refreshImages.bind(this);
        this.updateDs = this.updateDs.bind(this);
        this.checkboxChange = this.checkboxChange.bind(this);
        this.warningIconWrapper = this.warningIconWrapper.bind(this);
        this.directDragAndDropImageUpload = this.directDragAndDropImageUpload.bind(this);
        this.changeDamage = this.changeDamage.bind();
        this.showIconIfDamageIsImported = this.showIconIfDamageIsImported.bind(this);
        this.handleChangeComment = this.handleChangeComment.bind(this);

        this.updateBtnRef = React.createRef();
    }

    componentDidMount() {

        if(this.props.isRidTypeOrder) {
            const formattedComments = this.state.ridTypeComments.map(comment => ({
                value: comment,
                label: comment
            }));

            this.setState({
                ridTypeComments: formattedComments
            })
        }


        console.log("DAMAGE IS RID::: " + this.props.isRidTypeOrder);

        if (this.state.id) {
            getDamage(this.state.id)
            .then(r => {

                this.setState({
                    date: r.inspectionDate,
                    repaired: r.repaired,
                    repaired_date_time: r.repaired_date_time,
                    repairMethod: r.repairMethod,
                    images: r.images,
                    dsPart: r.ds_part,
                    dsType: r.ds_type,
                    dsSeverity: r.ds_severity,
                    dsCause: r.ds_cause,
                    dsRepairMode: r.ds_repair_mode,
                    dsClass: r.ds_class,
                    dsDln: r.ds_dln,
                    hash_id: r.hash_id,
                    clientComment: r.client_comment ? r.client_comment : '',
                    dimensions: r.dimensions,
                    inspectusComment: r.inspectus_comment ? r.inspectus_comment : '',
                    claimable: r.claimable,
                    damaged_in_transport: r.damaged_in_transport,
                    old: r.old,
                    is_imported: r.is_imported
                });

                var tempDamagImagePaths = [];

                if(r.images != null) {
                    r.images.forEach(element => {
                        getImage(element).then(resp => {
                            console.log(this.state.damageImagePaths);
                            console.log(resp.path)
                            tempDamagImagePaths.push(resp.path);
                            this.state.lightboxImages.push({
                                src: resp.path,
                                thumbnail: resp.path
                            })
                            // this.state.damageImagePaths.push(resp.image.path);
                            
                        })
                    });
                }

                this.setState({
                    damageImagePaths: tempDamagImagePaths
                });

            })
        }
    }

    handleSubmit(e) {
        e.preventDefault();

        //Save data and them proceed to remove damage from tempDamages state arr
        this.saveData()
            .then(() => {
                this.props.removeNewDamage(this.state.tempId);
            });
    }

    saveData() {
        return new Promise((resolve, reject) => {
            let data = {
                "dsPart": this.state.dsPart ? this.state.dsPart.id : null,
                "dsType": this.state.dsType ? this.state.dsType.id : null,
                "dsSeverity": this.state.dsSeverity ? this.state.dsSeverity.id : null,
                "dsCause": this.state.dsCause ? this.state.dsCause.id : null,
                "dsRepairMode": this.state.dsRepairMode ? this.state.dsRepairMode.id : null,
                "dsClass": this.state.dsClass ? this.state.dsClass.id : null,
                "dsDln": this.state.dsDln ? this.state.dsDln.id : null,
                "inspectusComment": this.state.inspectusComment,
                "clientComment": this.state.clientComment,
                "item_id": this.props.cargoItemId,
                "damaged_in_transport": this.state.damaged_in_transport,
                "claimable": this.state.claimable,
                "order_id": this.state.orderId,
                "dimensions": this.state.dimensions
            };

            console.log(this.state.orderId)

            if (this.state.id) {
                Axios.put(process.env.REACT_APP_API_BASE + '/damages/'+this.state.id, data, {
                    headers: {
                        'Authorization': 'Bearer ' + this.state.token
                    }
                } )
                    .then(response => {
                        if (response.status === 200) {
                            SuccessModal.fire({
                                title: 'Successfully updated damage',
                            })
                                .then(() => {
                                    resolve('success');
                                });
                        }
                    })
                    .catch(error => {
                        console.log(error);
                        this.setState({
                            'formErrors': error.response.data.message
                        }, () => {
                            reject(error.response.data.message);
                        });
                    })
            } else {
                Axios.post(process.env.REACT_APP_API_BASE + '/damages', data, {
                    headers: {
                        'Authorization': 'Bearer ' + this.state.token
                    }
                } )
                    .then(response => {

                        if (response.status === 201) {
                            this.setState({
                                id: response.data.damage.id
                            }, () => {
                                this.attachDamageToCargoItem(response.data.damage.id)
                                    .then(() => {
                                        this.props.refreshCargoData();
                                        SuccessModal.fire({
                                            title: 'Successfully added damage',
                                        })
                                        .then(() => {
                                            let node = document.querySelectorAll('#damage-'+response.data.damage.id+" .updateBtn");

                                            if (node && node.length > 0) {
                                                node[0].focus();
                                            }

                                            resolve('success');
                                        });
                                });
                            });
                        }
                    })
                    .catch(error => {
                        console.log(error);
                        this.setState({
                            'formErrors': error.response.data.message
                        }, () => {
                            reject(error.response.data.message);
                        });
                    })
            }
        });
    }

    handleChange(e) {
        this.setState({
            [e.target.name]: e.target.value
        });
    }


    removeNewDamage(e) {
        e.preventDefault();

        this.props.removeNewDamage(this.state.tempId);
    }

    removeExistingDamage(e) {
        e.preventDefault();

        e.preventDefault();
        DeleteItemPopup.fire({
            title: 'Confirm Deletion',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
        })
            .then((result) => {
                if (result.isConfirmed) {
                    return new Promise((resolve, reject) => {
                        let damages = [];
                        getCargoItem(this.state.cargoItemId).then(resp => {
                            damages = resp.damages;

                            let newDamages = damages.filter( e => {
                                return e !== this.state.id;
                            });

                            Axios.delete(process.env.REACT_APP_API_BASE + '/damages/'+ this.state.id, {
                                headers: {
                                    'Authorization': 'Bearer ' + Store.get('accessToken').token
                                }
                            }).then(r => {
                                if (r.status === 200) {
                                    if (r.status === 200) {
                                        this.props.refreshDamages().then(response => {
                                            SuccessModal.fire({
                                                title: 'Successfully removed damage',
                                            });
                                            resolve(response);
                                        });
                                    }
                                } else {
                                    // reject(r);
                                }
                            });

                           /* Axios.put(process.env.REACT_APP_API_BASE + '/damages/' + this.state.cargoItemId, {
                                'damages': newDamages,
                            }, {
                                headers: {
                                    'Authorization': 'Bearer ' + this.state.token
                                }
                            } )
                                .then(response => {
                                    if (response.status === 200) {
                                        this.props.refreshDamages().then(response => {
                                            SuccessModal.fire({
                                                title: 'Successfully removed damage',
                                            });
                                            resolve(response);
                                        });
                                    }
                                })
                                .catch(error => {
                                    reject(error);
                                })*/
                        });
                    });
                } else {
                    return false;
                }
            });
    }

    markRepaired(e) {
        e.preventDefault();

        repairModal.fire({
            title: 'Select a Repair Method',
            input: 'select',
            inputOptions: {
                '0': 'Repair Method 0',
                '1': 'Repair Method 1',
                '2': 'Repair Method 2',
            },
            inputAttributes: {
                'required': 'true',
            },
            inputValidator: (value) => {
                if (!value) {
                    return 'You need to choose something!'
                }
            },
            inputPlaceholder: 'Select repair method',
            showCancelButton: true,
        }).then((value) => {

            if (value.isConfirmed) {
                Axios.put(process.env.REACT_APP_API_BASE + '/damages/' + this.state.id, {
                    'repaired': true,
                    'repairMethod' : parseInt(value.value)
                }, {
                    headers: {
                        'Authorization': 'Bearer ' + this.state.token
                    }
                } )
                    .then(response => {

                        this.setState({
                            'repaired': true,
                            'repairMethod': response.data.damage.repairMethod,
                            repaired_date_time: response.data.damage.repaired_date_time,
                        }, () => {
                            SuccessModal.fire({
                                title: 'Successfully marked damage as repaired',
                            });
                        })
                    })
                    .catch(error => {
                        // reject(error);
                    })
            }
        });
    }

    uploadModalTrigger(e) {
        e.preventDefault();

        UploadImageModal.fire({
            title: 'Select image',
            input: 'file',
            showLoaderOnConfirm: true,
            showCancelButton: true,
            customClass: {
                container: 'file-upload-modal'
            },
            inputAttributes: {
                'accept': 'image/*',
                'multiple': 'multiple',
            },
            preConfirm: (inputValue) => {
                if (inputValue) {
                    return this.addDamageImage(inputValue)
                        .then(() => {
                            return this.refreshImages();
                        })
                        .catch(err => {
                        console.log(err);
                    });
                }
            }
        }).then(resp => {
            if (resp.value) {
                this.addDamageImage(resp.value)
                    .then(() => {
                        this.refreshImages();
                    })
                    .catch(err => {
                            console.log(err);
                    });
            }
        });

    }

    directDragAndDropImageUpload(imagesList) {
        if(imagesList) {
            this.addDamageImage(imagesList)
            .then(() => {
                return this.refreshImages();
            })
            .catch(err => {
            console.log(err);
            });
        }
    }

    refreshImages() {
        getDamage(this.state.id)
            .then(r => {
                this.setState({
                    images: r.images
                });
        });
    }

    
    handleChangeComment(obj, e) {
        this.setState({clientComment: obj.value})
    }



    addDamageImage(images) {

        LoadingModalSwal.fire({
            title: 'Please wait. Uploading images',
            showLoaderOnConfirm: false,
            showCancelButton: false,
            didOpen: () => {
                Swal.showLoading()
            }
        });
        return new Promise((resolve, reject) => {
            if (!images) throw new Error('Missing images');

            let formData = new FormData();

            for (let i = 0; i < images.length; i++) {
                formData.append('image[]', images[i]);
            }
            formData.append('damage_id', this.state.id);

            Axios.post(process.env.REACT_APP_API_BASE + '/images', formData, {
                headers: {
                    'Authorization': 'Bearer ' + this.state.token
                }
            } )
                .then(response => {


                    if (response.status === 200) {
                        this.props.refreshCargoData();
                        SuccessModal.fire({
                            title: 'Successfully added image',
                        }).then(() => {
                            resolve();
                        });
                    }
                })
                .catch(error => {
                    console.log(error);
                    this.setState({
                        'formErrors': error.response.data.message
                    }, () => {
                        reject(error.response.data.message);
                    });
                })
        });
    }


    //If damage instance has been created and has an ID, display contents provided as data param
    conditionallyDisplay(data) {
        if (this.state.id) {
            return data;
        }
    }


    attachDamageToCargoItem(itemId) {
        return new Promise((resolve, reject) => {
            if (!itemId) {
                reject('missing id');
            }

            let damages = [];
            getCargoItem(this.state.cargoItemId).then(resp => {
                damages = resp.damages;
                damages.push(itemId);

                Axios.put(process.env.REACT_APP_API_BASE + '/items/' + this.state.cargoItemId, {
                    'damages': damages,
                }, {
                    headers: {
                        'Authorization': 'Bearer ' + this.state.token
                    }
                } )
                    .then(response => {
                        if (response.status === 200) {
                            resolve(response);
                        }
                    })
                    .catch(error => {
                        reject(error);
                    })
            });

        });
    }

    repairButtonConditional(data) {
        if (this.state.id && !this.state.repaired) {
            return data;
        }
    }

    updateDs(name, value) {

        this.setState({
            [name]: value
        })
    }

    checkboxChange(e) {

        this.setState({
            [e.target.name]: e.target.checked
        });
    }

    warningIconWrapper() {
        return (
            <div className={Style.warningIcon}>
                <WarningIcon />
            </div>
        )
    }

    changeDamage = (e) => {
        if (e.target.checked) {
            this.props.selectDamage(e.target.value);
        } else {
            this.props.unselectDamage(e.target.value);
        }
    }

    showIconIfDamageIsImported() {
        console.log("HAHHAHA");
        console.log(this.state.is_imported);
        console.log("HAHHAHA");
        if(this.state.is_imported === 1) {
            return true;
        } else {
            return false;
        }
    }

    render() {
        return (
        <div id={"damage-"+ (this.state.id || this.props.tempId)} className={"damage-item " + Style.item}>

            <input
                className="form-check-input mb-3"
                type="checkbox"
                value={this.state.id}
                id={'check-'+this.state.id}
                name="damageCheck"
                checked={this.props.selectedItems?.includes(this.state.id)}
                onChange={this.changeDamage}
            />
            {this.state.is_imported === 1 ? <img className={"float-end"} src={importedDamageIcon} width="24" height="24" /> : ''}
            {this.state.old === 1 ?
                this.warningIconWrapper() : null}
                <form action="" onSubmit={this.handleSubmit}>
                    <div className="row">
                        <div className="col-12 col-md-9">
                            {!this.props.isRidTypeOrder ? 
                                <div className="layout-grid-7">
                                    <div className="ds-item">
                                        <DsInput existingValue={this.state.dsPart} type={"part"} label={"Part"} name={"dsPart"} updateParent={this.updateDs} {...this.props}/>
                                    </div>
                                    <div className="ds-item">
                                        <DsInput existingValue={this.state.dsType} type={"type"} label={"Type"} name={"dsType"} updateParent={this.updateDs} {...this.props}/>
                                    </div>
                                    <div className="ds-item">
                                        <DsInput existingValue={this.state.dsSeverity} type={"severity"} label={"Severity"} name={"dsSeverity"} updateParent={this.updateDs} {...this.props}/>
                                    </div>
                                    <div className="ds-item">
                                        <DsInput existingValue={this.state.dsCause} type={"cause"} label={"Cause"} name={"dsCause"} updateParent={this.updateDs} {...this.props}/>
                                    </div>
                                    <div className="ds-item">
                                        <DsInput existingValue={this.state.dsRepairMode} type={"repair_mode"} label={"Repair Mode"} name={"dsRepairMode"} updateParent={this.updateDs} {...this.props}/>
                                    </div>
                                    <div className="ds-item">
                                        <DsInput existingValue={this.state.dsClass} type={"class"} label={"Class"} name={"dsClass"} updateParent={this.updateDs} {...this.props}/>
                                    </div>
                                    <div className="ds-item">
                                        <DsInput existingValue={this.state.dsDln} type={"dln"} label={"DLN"} name={"dsDln"} updateParent={this.updateDs} {...this.props}/>
                                    </div>
                                    <div className="ds-item">
                                        <label htmlFor="dimensions" className={"mb-1"}>Dimensions</label>
                                        <input type="text" name="dimensions" id={"dimensions"} placeholder="Dimensions" className="vinSearch form-control w-100" value={this.state.dimensions} onChange={this.handleChange}></input>
                                    </div>
                                </div>
                                : 
                                <div className="layout-grid-7">
                                    <div className="ds-item">
                                        <DsInput existingValue={this.state.dsPart} type={"part"} label={"Part"} name={"dsPart"} updateParent={this.updateDs} {...this.props}/>
                                    </div>
                                    
                                    <div className="ds-item">
                                        <DsInput existingValue={this.state.dsRepairMode} type={"repair_mode"} label={"Service"} name={"dsRepairMode"} updateParent={this.updateDs} {...this.props}/>
                                    </div>
                                    <div className="ds-item">
                                        <DsInput existingValue={this.state.dsClass} type={"class"} label={"Class"} name={"dsClass"} updateParent={this.updateDs} {...this.props}/>
                                    </div>
                                    
                                    <div className="ds-item">
                                        <input type="text" name="dimensions" id={"dimensions"} placeholder="Quantity" className="vinSearch form-control w-100" value={this.state.dimensions} onChange={this.handleChange}></input>
                                    </div>

                                   <div className="ds-item">
                                    <BaseSelect
                                            closeMenuOnSelect={true}
                                            options={this.state.ridTypeComments}
                                            name="selectedTransports"
                                            value={this.state.selectedComment}
                                            className="basic-multi-select filter-multi-select w-100"
                                            classNamePrefix="select"
                                            onChange={this.handleChangeComment}
                                        />
                                    </div>
                                </div>}
                            {this.conditionallyDisplay(
                                <div className="row mt-2">
                                    <div className="col-12">
                                        <div>
                                            {this.state.images ? <DamageImages allowUpload={true} dmgId={this.state.id} refreshImages={this.refreshImages} directDragAndDropImageUpload={this.directDragAndDropImageUpload} uploadTrigger={this.uploadModalTrigger} images={this.state.images} damageImagePaths={this.state.damageImagePaths} lightboxImages={this.state.lightboxImages}/> : null}
                                        </div>
                                    </div>
                                </div>
                            )}
                            <div className="row mt-3">
                                <div className="col-12 col-md-3">
                                    <label htmlFor="inspectusComment" className="text-uppercase strong mb-2">Comments client</label>
                                    <textarea placeholder={"Insert comment"} name="clientComment" onChange={this.handleChange} value={this.state.clientComment} id="inspectusComment" cols="30" rows="5" className={"form-control"} />
                                </div>
                                <ConditionalContent check={!isNotClient()} className="col-12 col-md-3">
                                    <label htmlFor="inspectusComment" className="text-uppercase strong mb-2">Comments inspectus</label>
                                    <textarea placeholder={"Insert comment"} name="inspectusComment" onChange={this.handleChange} value={this.state.inspectusComment} id="inspectusComment" cols="30" rows="5" className={"form-control"} />
                                </ConditionalContent>

                                <div className="col-12 col-md-6">
                                    <div>
                                        <label htmlFor="inspectusComment" className="text-uppercase strong mb-2">Misc.</label>
                                    </div>
                                    <div className="form-check form-check-inline">
                                        <input
                                            className="form-check-input"
                                            type="checkbox"
                                            id={`${this.props.cargoItemId}-${this.state.id || this.props.tempId}-damaged_in_transport`}
                                            name="damaged_in_transport"
                                            checked={this.state.damaged_in_transport}
                                            onChange={this.checkboxChange}
                                        />
                                        <label className="form-check-label" htmlFor={`${this.props.cargoItemId}-${this.state.id || this.props.tempId}-damaged_in_transport`}>
                                            Damaged in transport
                                        </label>
                                    </div>
                                    <div className="form-check form-check-inline">
                                        <input
                                            className="form-check-input"
                                            type="checkbox"
                                            id={`${this.props.cargoItemId}-${this.state.id || this.props.tempId}-claimable`}
                                            name="claimable"
                                            checked={this.state.claimable}
                                            onChange={this.checkboxChange}
                                        />
                                        <label className="form-check-label" htmlFor={`${this.props.cargoItemId}-${this.state.id || this.props.tempId}-claimable`}>
                                            Claimable
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-md-3">
                            <div className={"d-flex justify-content-end"}>
                                <button type={"submit"} className={"btn btn-primary me-2 updateBtn"}>Update</button>
                                <button onClick={this.state.id ? this.removeExistingDamage : this.removeNewDamage} className={"btn btn-primary"}>Remove</button>
                            </div>
                            <div className="mt-2 text-end">
                                <span className="d-block">Damage Added:</span>
                                <span>{this.state.date ? displayDate(this.state.date) : '/'}</span>
                            </div>

                                <div className="mt-2 d-flex justify-content-end">
                                    <ConditionalContent check={this.state.id && !this.state.repaired}>
                                        <button onClick={this.markRepaired} className={"btn btn-secondary"}>Mark as repaired</button>
                                    </ConditionalContent>
                                    <ConditionalContent check={this.state.id && this.state.repaired}>
                                        <span>Repaired: {this.state.repaired_date_time ? displayDate(this.state.repaired_date_time) : '/'}</span>
                                    </ConditionalContent>
                                </div>
                        </div>
                    </div>
                </form>
            </div>
        );
    }
}

Damage.propTypes = {};

export default Damage;
