import React from "react";
import {Link} from "react-router-dom";
import {
    fetchItemsFromArr,
    getAdmins,
    getClients, getDamageSets, getInspector,
    getInspectors, getTransports, isClient, isAdmin, isManager, getManagers, getTransportCompanies
} from "../../../../utils/utils";

import Axios from "axios";
import Store from "store";
import inspectionTypes from "../../../../fixedData/inspectionTypes";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import Gallery from "../../../../Gallery/Gallery";
import {FileUploader} from "../../../../FileUploader/FileUploader";
import FixRequiredSelect from "../../../../utils/FixRequiredSelect";
import BaseSelect from 'react-select';
import ConditionalContent from "../../../../ConditionalContent";
import DatePicker from "react-datepicker";
import moment from 'moment';
const SuccessModal = withReactContent(Swal);

class EditOrder extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            id: this.props.match.params.entryId,
            token: Store.get('accessToken').token,
            inspectors: [],
            admin: null,
            client: null,
            notes: '',
            adminsArr: [],
            inspectorsArr: [],
            clientsArr: [],
            transportsArr: [],
            damageSetsArr: [],
            damageSetId: null,
            damageSet: null,
            transportType: '',
            gate: '',
            inspectionType: '',
            transports: [],
            existingImages: [],
            'cargo_type': '',
            jointSurvey: false,
            receivingParty: false,
            sendingParty: false,
            damage_set_changed:false,
            transportCompaniesArr: [],
            surveyStart: '',
            surveyEnd: '',
            departureDate: '',
            arrivalDate: '',
            referenceNumberInspectus: '',
            referenceNumberClient: '',
            surveyStartTime: '',
            surveyEndTime: '',
            loadingDateExport: '',
            siteOfInspection: '',
            transportType: null,
            transportCompany: null,
            selectedShortcuts: [],
            selectedShortcutIds: [],
            shortcuts: [],
            'isRidOrderType': false
        };

        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleChangeDamageSet = this.handleChangeDamageSet.bind(this);
        this.handleSelectChange = this.handleSelectChange.bind(this);
        this.handleMultiSelectChange = this.handleMultiSelectChange.bind(this);
        this.populateSelectFields = this.populateSelectFields.bind(this);
        this.setSelectedFile = this.setSelectedFile.bind(this);
        this.addImages = this.addImages.bind(this);
        this.refreshImages = this.refreshImages.bind(this);
        this.setArrivalDate = this.setArrivalDate.bind(this);
        this.setDepartureDate = this.setDepartureDate.bind(this);
        this.setSurveyStart = this.setSurveyStart.bind(this);
        this.setSurveyEnd = this.setSurveyEnd.bind(this);
        this.jointSurveyHandleChange = this.jointSurveyHandleChange.bind(this);
        this.receivingPartyHandleChange = this.receivingPartyHandleChange.bind(this);
        this.radioSelected = this.radioSelected.bind(this);
        this.handleMultiSelectChangeShortcuts = this.handleMultiSelectChangeShortcuts.bind(this);
    }

    handleSubmit(e) {
        e.preventDefault();

        SuccessModal.showLoading();
        console.log('arrivalDDate: ' + this.state.arrivalDate);
        console.log('departureDate: ' + this.state.departureDate);
        console.log('surveyStart: ' + this.state.surveyStart);
        console.log('surveyEnd:' + this.state.surveyEnd);

        let tempSelectedShortcutIds = [];
        for(let item of this.state.selectedShortcuts) {
            tempSelectedShortcutIds.push(item.id);
        }

        this.setState({
            selectedShortcutIds: tempSelectedShortcutIds
        })

        let data = {
            'admin_id': this.state.admin ? this.state.admin.value : null,
            'client_id': this.state.client ? this.state.client.value : null,
            'inspectors': this.state.inspectors && this.state.inspectors.length > 0 ? this.state.inspectors.map(i => i.value) : [],
            'orderNotes': this.state.notes,
            'damageSetId': this.state.damageSet ? this.state.damageSet.value : null,
            'transportType': this.state.transportType,
            'gate': this.state.gate,
            'inspectionType':this.state.inspectionType,
            'transportIds': this.state.transports && this.state.transports.length > 0 ? this.state.transports.map(i => i.value) : [],
            'cargo_type': this.state.cargo_type,
            'referenceNumberInspectus': this.state.referenceNumberInspectus,
            'referenceNumberClient': this.state.referenceNumberClient,
            'arrivalDate': this.state.arrivalDate ? moment(this.state.arrivalDate).format("YYYY-MM-DD") : "",
            'departureDate': this.state.departureDate ? moment(this.state.departureDate).format("YYYY-MM-DD") : "",
            'surveyStart': this.state.surveyStart ? moment(this.state.surveyStart).format("YYYY-MM-DD") : "",
            'surveyEnd': this.state.surveyEnd ? moment(this.state.surveyEnd).format("YYYY-MM-DD") : "",
            'surveyStartTime': this.state.surveyStartTime,
            'surveyEndTime': this.state.surveyEndTime,
            'loadingDateExport': this.state.loadingDateExport ? moment(this.state.loadingDateExport).format("YYYY-MM-DD") : "",
            'siteOfInspection': this.state.siteOfInspection,
            'transportType': this.state.transportType,
            'jointSurvey': this.state.jointSurvey,
            'receivingParty': this.state.receivingParty,
            'sendingParty': this.state.sendingParty,
            'transportCompany': this.state.transportCompany ? this.state.transportCompany.value : null,
            'shortcuts': this.state.selectedShortcuts && this.state.selectedShortcuts.length > 0 ? this.state.selectedShortcuts.map(i => i.value) : [],
            'is_rid_type': this.state.isRidOrderType
        };

        Axios.put(process.env.REACT_APP_API_BASE + '/orders/' + this.state.id, data, {
            headers: {
                'Authorization': 'Bearer ' + this.state.token
            }
        } )
            .then(response => {
                if (response.status === 200) {

                    if (this.state.images) {
                        this.addImages(this.state.images).then(r => {
                            SuccessModal.fire({
                                title: 'Successfully edited order',
                                icon: 'success'
                            }).then(() => {
                                this.props.history.push('/dashboard/orders/order/'+this.state.id);
                            });
                        });
                    } else {
                        SuccessModal.fire({
                            title: 'Successfully edited order',
                            icon: 'success'
                        }).then(() => {
                            this.props.history.push('/dashboard/orders/order/'+this.state.id);
                        });
                    }
                } else {
                    throw new Error('Something went wrong');
                }
            })
            .catch(() => {
                SuccessModal.fire({
                    title: 'Something went wrong' ,
                    icon: 'error'
                });
            })
    }

    radioSelected(e) {
        let selection = e.target.value;
        if(selection === 'true') {
            this.setState({
                [e.target.name]: true
            })
        } else {
            this.setState({
                [e.target.name]: false
            })
        }
    }

    jointSurveyHandleChange(e) {
        if(e.target.value === 'true') {
            this.setState({
                jointSurvey: true
            })
        } else {
            this.setState({
                jointSurvey: false
            })
        }
    }

    receivingPartyHandleChange(e) {
        if(e.target.value === 'true') {
            this.setState({
                receivingParty: true
            })
        } else {
            this.setState({
                receivingParty: false
            })
        }
    }

    setArrivalDate(date) {
        console.log(date);

        this.setState({
            arrivalDate: date
        });
    }

    setDepartureDate(date) {
        console.log(date);

        this.setState({
            departureDate: date
        });
    }

    setSurveyStart(date) {

        this.setState({
            surveyStart:  date
        });
    }

    setLoadingDateExport(date) {

        this.setState({
            loadingDateExport:  date
        });
    }

    setSurveyEnd(date) {

        this.setState({
            surveyEnd:  date
        });
    }



    componentDidMount() {

        this.populateSelectFields();

        let config = {};

        config.headers = {
            'Authorization': 'Bearer ' + this.state.token
        };

        Axios.get(process.env.REACT_APP_API_BASE + '/orders/'+this.state.id+'/app', config).then(response => {
            if (response.status === 200) {

                let transports = [];
                if (response.data.order.transports && response.data.order.transports.length > 0) {
                    for (let t of response.data.order.transports) {
                        transports.push({
                            value: t.id,
                            label: t.identification_name
                        })
                    }
                }

                if (response.data.order.inspectors && response.data.order.inspectors.length > 0) {
                    fetchItemsFromArr(response.data.order.inspectors, getInspector)
                        .then(r => {
                            return r.map(item => {
                                return {
                                    value: item.id,
                                    label: item.name
                                }
                            })
                        })
                        .then(obj => {
                            this.setState({
                                inspectors: obj
                            })
                        })
                        .catch(err => {
                            console.log('smt went wrong with populating inspectors');
                        })
                }

                var _arrivalDate = '';
                var _departureDate = '';
                var _surveyStart = '';
                var _surveyEnd = '';
                var _loadingDateExport = '';
                var _jointSurvey = false;
                var _sendingParty = false;
                var _receivingParty = false;

                
                this.state.isRidOrderType = response.data.order.is_rid_type === 1 ? true : false;

                if(response.data.order.arrival_date != null) {
                    _arrivalDate = new Date(response.data.order.arrival_date)
                    this.state.arrivalDate = _arrivalDate
                }

                if(response.data.order.departure_date != null) {
                    _departureDate = new Date(response.data.order.arrival_date)
                    this.state.departureDate = _departureDate;
                }

                if(response.data.order.survey_start != null) {
                    _surveyStart = new Date(response.data.order.survey_start)
                    this.state.surveyStart = _surveyStart
                }
                
                if(response.data.order.survey_end != null) {
                    _surveyEnd = new Date(response.data.order.survey_end)
                    this.state.surveyEnd = _surveyEnd
                }

                if(response.data.order.loading_date_export != null) {
                    _loadingDateExport = new Date(response.data.order.loading_date_export)
                    this.state.loadingDateExport = _loadingDateExport
                }

                if(response.data.order.survey_start_time != null) {
                    this.state.surveyStartTime = response.data.order.survey_start_time
                }

                if(response.data.order.survey_end_time != null) {
                    this.state.surveyEndTime = response.data.order.survey_end_time
                }

                if(response.data.order.joint_survey != null) {
                    _jointSurvey = response.data.order.joint_survey == 1 ? true : false
                    this.state.jointSurvey = _jointSurvey
                }

                if(response.data.order.sending_party != null) {
                    _sendingParty = response.data.order.sending_party == 1 ? true : false
                    this.state.sendingParty = _sendingParty
                }

                if(response.data.order.receiving_party != null) {
                    _receivingParty= response.data.order.receiving_party == 1 ? true : false
                    this.state.receivingParty = _receivingParty
                }
                let tempShortcuts = [];
                let tempSelectedShortcuts = [];
                let tempSelectedShortcutIds = [];

                if(response.data.order.damageSet != null) {
                    // Pridobimo shortcute glede na izbrani damageset 
                     Axios.get(process.env.REACT_APP_API_BASE + '/shortcutsByDamageSet/' + response.data.order.damageSet?.id, {
                        headers: {
                            'Authorization': 'Bearer ' + this.state.token
                        }
                    })
                        .then(res => {
                            for (const item of res.data) {
                                tempShortcuts.push({
                                    'value': item.id,
                                    'label': item.name
                                });
                            }
                            this.setState({
                                shortcuts: tempShortcuts
                            })
                        }).then(x => {
                            // Pridobimo shortcute kateri so bili izbrani za ta order
                            Axios.get(process.env.REACT_APP_API_BASE + '/order/'+ response.data.order.id + '/shortcuts', {
                                headers: {
                                    'Authorization': 'Bearer ' + this.state.token
                                }
                            })
                            .then(res => {
                                for (const item of res.data) {
                                    tempSelectedShortcuts.push({
                                        'value': item.id,
                                        'label': item.name
                                    });

                                    tempSelectedShortcutIds.push(item.id);
                                }

                                this.setState({
                                    selectedShortcutIds: tempSelectedShortcutIds,
                                    selectedShortcuts: tempSelectedShortcuts
                                })
                            });
                        })
                    
                    
                }

                this.setState({
                    data: response.data.order,
                    damage_set_changed: response.data.order.damage_set_changed,
                    'cargo_type': response.data.order.cargo_type,
                    client: {
                        value: response.data.order.client?.id,
                        label: response.data.order.client?.company_name
                    },

                    transportCompany: {
                        value: response.data.order.transportCompany?.id,
                        label: response.data.order.transportCompany?.name
                    },
                    
                    admin: {
                        value: response.data.order.admin?.id,
                        label: response.data.order.admin?.name
                    },
                    damageSet: {
                        value: response.data.order.damageSet?.id,
                        label: response.data.order.damageSet?.name
                    },
                    transports: transports,
                    gate: response.data.order.gate,
                    transportType: response.data.order.transportType,
                    inspectionType: response.data.order.inspectionType,
                    notes: response.data.order.order_notes,
                    existingImages: response.data.order.images ? response.data.order.images : [],
                    referenceNumberInspectus: response.data.order.reference_number_inspectus,
                    referenceNumberClient: response.data.order.reference_number_client,
                    arrivalDate: _arrivalDate,
                    departureDate: _departureDate,
                    surveyStart: _surveyStart,
                    surveyEnd: _surveyEnd,
                    jointSurvey: _jointSurvey,
                });
            } else {
                throw new Error('Smt went wrong with fetching orders');
            }
        }).catch(err => {
            console.log(err);
            if (err.response?.status === 401) {
                console.log('attempting reauth');
            }
        });
    }


    populateSelectFields() {
        let tempAdmins = [];

        getManagers().then(resp => {
            console.log(resp);
            for (const item of resp) {
                tempAdmins.push({
                    'value': item.id,
                    'label': item.name + ' (' + item.email + ')'
                });
            }

            this.setState({
                'adminsArr': tempAdmins
            });
        });


        var tempInspectors = [];
        getInspectors().then(resp => {

            for (const item of resp) {
                tempInspectors.push({
                    'value': item.id,
                    'label': item.name + ' (' + item.email + ')'
                });
            }
        }).then(() => {
            this.setState({
                'inspectorsArr': tempInspectors
            });
        });

        let transportCompaniesArr = [];

        getTransportCompanies()
            .then(resp => {
                for (const item of resp) {
                    transportCompaniesArr.push({
                        'value': item.id,
                        'label': item.name + ' (' + item.code + ')'
                    });
                }
            }).then(() => {
                this.setState({
                    'transportCompaniesArr': transportCompaniesArr
                });
            });


        var tempClients = [];
        getClients().then(resp => {

            for (const item of resp) {
                tempClients.push({
                    'value': item.id,
                    'label': item.name + ' (' + item.email + ')'
                });
            }
        }).then(() => {
            this.setState({
                'clientsArr': tempClients
            });
        });

        let tempDamageSets = [];
        getDamageSets()
            .then(resp => {
                for (const item of resp) {
                    tempDamageSets.push({
                        'value': item.id,
                        'label': item.name
                    });
                }
            })
            .then(() => {
                this.setState({
                    damageSetsArr: tempDamageSets
                });
            });

        let tempTransports = [];
        getTransports()
            .then(resp => {
                for (const item of resp) {
                    tempTransports.push({
                        'value': item.id,
                        'label': item.identificationName
                    });
                }
            })

            .then(() => {
                tempTransports.sort((a, b) => a.label > b.label ? 1 : -1)

                this.setState({
                    'transportsArr': tempTransports
                });
            });
    }

    handleChange(e) {
        this.setState({
            [e.target.name]: e.target.value
        }); 

        if (e.target.name === 'gate') {
            this.setState({
                inspectionType: ''
            });
        }
    }


    handleChangeDamageSet(obj, e) {
        this.setState({
            [e.name]: obj,
            selectedShortcuts: []
        })

        let tempShortcuts = [];

        // Pridobiti moramo vse shortcute glede na izbrane damage sete
        Axios.get(process.env.REACT_APP_API_BASE + '/shortcutsByDamageSet/' + obj.value, {
            headers: {
                'Authorization': 'Bearer ' + this.state.token
            }
        })
            .then(res => {
                for (const item of res.data) {
                    tempShortcuts.push({
                        'value': item.id,
                        'label': item.name
                    });
                }
            }).then(() => {
                
                this.setState({
                    shortcuts: tempShortcuts
                });
            })
    }


    handleSelectChange(obj, e) {

        this.setState({
            [e.name]: obj
        })
    }

    handleMultiSelectChange(obj, e) {
        this.setState({
            [e.name]: obj
        })
    }

    handleMultiSelectChangeShortcuts(obj, e) {

        let itemsIdArr = [];

        for (let item of obj) {
            itemsIdArr.push(item.value);
        }

        this.setState({
            [e.name]: itemsIdArr
        })
    }




    inspectionTypeSelect() {
        if (inspectionTypes) {
            if (this.state.gate) {
                return (
                    <select className={"form-select"} name={"inspectionType"} id={"inspectionType"} value={this.state.inspectionType} onChange={this.handleChange}>
                        <option value="">Select Inspection Type</option>
                        {inspectionTypes[this.state.gate].map((item, index) => {
                            return (
                                <option value={item}>{item}</option>
                            );
                        })}
                    </select>
                )
            } else {
                return (
                    <input type="text" placeholder={"Select gate first"} disabled className={"form-control"}/>
                )
            }
        }
    }

    addImages(images) {

        return new Promise((resolve, reject) => {
            if (!images) throw new Error('Missing images');

            let formData = new FormData();

            for (let i = 0; i < images.length; i++) {
                formData.append('image[]', images[i]);
            }
            formData.append('order_id', this.state.id);

            Axios.post(process.env.REACT_APP_API_BASE + '/orderimages', formData, {
                headers: {
                    'Authorization': 'Bearer ' + this.state.token
                }
            } )
                .then(response => {
                    if (response.status === 200) {
                        resolve();
                    }
                })
                .catch(error => {
                    console.log(error);
                    this.setState({
                        'formErrors': error.response.data.message
                    }, () => {
                        reject(error.response.data.message);
                    });
                })
        });
    }

    setSelectedFile(file) {
        this.setState({
            images: file
        })
    }

    refreshImages() {
        let config = {};

        config.headers = {
            'Authorization': 'Bearer ' + Store.get('accessToken').token
        };
        Axios.get(process.env.REACT_APP_API_BASE + '/orders/'+this.state.id, config).then(response => {
            if (response.status === 200) {
                this.setState({
                    existingImages: response.data.order.images ? response.data.order.images : []
                })
            }
        });
    }


    render() {
        return (
            <div className="container pb-10" id="main">
                <div className="row mb-5">
                    <div className="col-12 col-md-8">
                        <Link to={"/dashboard/orders/"} className={"btn-min"}>
                            <span className="ml-1">View all orders</span>
                        </Link>
                        <h1 className={"mt-1"}>Edit Order: {this.state.data ? this.state.data.idNumber : null}</h1>
                    </div>
                </div>

                <div className="row">
                    <div className="col-12 col-md-8">
                        <ConditionalContent check={isAdmin()}>
                            <form action="" className={"mb-5"} onSubmit={this.handleSubmit}>

                                <div className="row mb-3">
                                    <div className="row mb-3">
                                        <div className="col-12 col-md-6">
                                            <div className="form-group">
                                                <label htmlFor="referenceNumberInspectus">Reference number
                                                    inspectus</label>
                                                <input type="text" className={"form-control"}
                                                       name={"referenceNumberInspectus"} id={"referenceNumberInspectus"}
                                                       value={this.state.referenceNumberInspectus}
                                                       onChange={this.handleChange}/>
                                            </div>
                                        </div>
                                        <div className="col-12 col-md-6">
                                            <div className="form-group">
                                                <label htmlFor="referenceNumberClient">Reference number client</label>
                                                <input type="text" className={"form-control"}
                                                       name={"referenceNumberClient"} id={"referenceNumberClient"}
                                                       value={this.state.referenceNumberClient}
                                                       onChange={this.handleChange}/>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row mb-3">
                                        <div className="col-12 col-md-6">
                                            <div className="form-group">
                                                <label htmlFor="arrival-date">Arrival date</label>
                                                <DatePicker className={'form-control'} dateFormat="dd.MM.yyyy"
                                                            selected={this.state.arrivalDate}
                                                            utcOffset={0}
                                                            onChange={(date) => this.setArrivalDate(date)}/>
                                            </div>
                                        </div>
                                        <div className="col-12 col-md-6">
                                            <div className="form-group">
                                                <label htmlFor="discharging-date">Departure date</label>
                                                <DatePicker className={'form-control'} dateFormat="dd.MM.yyyy"
                                                            selected={this.state.departureDate}
                                                            utcOffset={0}
                                                            onChange={(date) => this.setDepartureDate(date)}/>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row mb-3">
                                        <div className="col-12 col-md-6">
                                            <div className="form-group">
                                                <label htmlFor="arrival-date">Survey start</label>
                                                <DatePicker className={'form-control'} dateFormat="dd.MM.yyyy"
                                                            selected={this.state.surveyStart}
                                                            utcOffset={0}
                                                            onChange={(date) => this.setSurveyStart(date)}/>
                                            </div>
                                        </div>
                                        <div className="col-12 col-md-6">
                                            <div className="form-group">
                                                <label htmlFor="discharging-date">Survey end</label>
                                                <DatePicker className={'form-control'} dateFormat="dd.MM.yyyy"
                                                            selected={this.state.surveyEnd}
                                                            utcOffset={0}
                                                            onChange={(date) => this.setSurveyEnd(date)}/>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row mb-3">
                                        <div className="col-12 col-md-6">
                                            <div className="form-group">
                                                <label htmlFor="arrival-date">Loading date export</label>
                                                <DatePicker className={'form-control'} dateFormat="dd.MM.yyyy"
                                                            selected={this.state.loadingDateExport}
                                                            utcOffset={0}
                                                            onChange={(date) => this.setLoadingDateExport(date)}/>
                                            </div>
                                        </div>
                                        <div className="col-12 col-md-6">
                                            <div className="form-group">
                                                <label htmlFor="discharging-date">Survey end</label>
                                                <DatePicker className={'form-control'} dateFormat="dd.MM.yyyy"
                                                            selected={this.state.surveyEnd}
                                                            utcOffset={0}
                                                            onChange={(date) => this.setSurveyEnd(date)}/>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="row mb-3">
                                        <div className="col-12 col-md-6">
                                            <div className="form-group">
                                                <label htmlFor="referenceNumberInspectus">Survey start time:</label>
                                                <input type="text" placeholder={"10:00"} className={"form-control"} name={"surveyStartTime"} id={"surveyStartTime"} value={this.state.surveyStartTime} onChange={this.handleChange}/>
                                            </div>
                                        </div>
                                        <div className="col-12 col-md-6">
                                            <div className="form-group">
                                                <label htmlFor="referenceNumberClient">Survey end time:</label>
                                                <input type="text" placeholder={"15:00"} className={"form-control"} name={"surveyEndTime"} id={"surveyEndTime"} value={this.state.surveyEndTime} onChange={this.handleChange}/>
                                            </div>
                                        </div>
                                    </div>

                                    

                                    <div className="col-12 col-md-6">
                                        <div className="form-group">
                                            <label htmlFor="transportType">Transport Type</label>
                                            <select className={"form-select"} name={"transportType"} id={"transportType"} value={this.state.transportType} onChange={this.handleChange}>
                                                <option disabled value="">Select Transport Type</option>
                                                <option value="vessel">Vessel</option>
                                                <option value="truck">Truck</option>
                                                <option value="train">Train (railcar)</option>
                                                <option value="container">Container</option>
                                                <option value="shuttle">Shuttle</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-6">
                                        <div className="form-group">
                                            <label htmlFor="client">Client*</label>
                                            <FixRequiredSelect
                                                SelectComponent={BaseSelect}
                                                closeMenuOnSelect={true}
                                                options={this.state.clientsArr}
                                                onChange={this.handleSelectChange}
                                                value={this.state.client}
                                                name={"client"}
                                                required
                                            />
                                        </div>
                                    </div>

                                    
                                </div>

                                <div className="row mb-3">
                                    <div className="col-12 col-md-6">
                                        <div className="form-group">
                                            <label htmlFor="client">Transport Company*</label>
                                            <FixRequiredSelect
                                                SelectComponent={BaseSelect}
                                                closeMenuOnSelect={true}
                                                options={this.state.transportCompaniesArr}
                                                onChange={this.handleSelectChange}
                                                value={this.state.transportCompany}
                                                name={"transportCompany"}
                                                required
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div className="row mb-3">
                                    <div className="col-12 col-md-6">
                                        <div className="form-group">
                                            <label htmlFor="owner">Order manager*</label>

                                            <FixRequiredSelect
                                                value={this.state.admin}
                                                SelectComponent={BaseSelect}
                                                closeMenuOnSelect={true}
                                                options={this.state.adminsArr}
                                                onChange={this.handleSelectChange}
                                                name={"admin"}
                                                required
                                            />
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-6">
                                        <div className="form-group">
                                            <label htmlFor="name">Inspectors</label>
                                            <FixRequiredSelect
                                                SelectComponent={BaseSelect}
                                                closeMenuOnSelect={true}
                                                isMulti
                                                name={"inspectors"}
                                                value={this.state.inspectors}
                                                options={this.state.inspectorsArr}
                                                onChange={this.handleMultiSelectChange}
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-12 col-md-6">
                                        <div className="form-group">
                                            <label htmlFor="name">Damage Set</label>
                                            <BaseSelect
                                                closeMenuOnSelect={true}
                                                options={this.state.damageSetsArr}
                                                name="damageSet"
                                                value={this.state.damageSet}
                                                className="basic-multi-select"
                                                classNamePrefix="select"
                                                isDisabled={this.state.damage_set_changed}
                                                onChange={this.handleChangeDamageSet}
                                            />
                                        </div>
                                    </div>

                                    <div className="col-12 col-md-6">
                                        <div className="form-group">
                                            <label htmlFor="name">Damage Set Shortcuts</label>
                                            <BaseSelect
                                                closeMenuOnSelect={true}
                                                isMulti
                                                options={this.state.shortcuts}
                                                onChange={this.handleMultiSelectChange}
                                                name={"selectedShortcuts"}
                                                value={this.state.selectedShortcuts}
                                            />
                                        </div>
                                    </div>

                                </div>
                                <div className="row mt-3">
                                    <div className="col-12 col-md-6">
                                        <div className="form-group">
                                            <label htmlFor="gate">Gate</label>
                                            <select className={"form-select"} name={"gate"} id={"gate"} value={this.state.gate} onChange={this.handleChange}>
                                                <option disabled value="">Select gate</option>
                                                <option value="arrival">Arrival</option>
                                                <option value="departure">Departure</option>
                                                <option value="internal">Internal</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-6">
                                        <div className="form-group">
                                            <label htmlFor="inspectionType">Inspection Type</label>
                                            {this.inspectionTypeSelect()}
                                        </div>
                                    </div>
                                </div>
                                <div className="row mt-3">
                                    <div className="col-12 col-md-6">
                                        <div className="form-group">
                                            <label htmlFor="transports">Transports</label>
                                            <BaseSelect
                                                closeMenuOnSelect={true}
                                                isMulti
                                                options={this.state.transportsArr}
                                                onChange={this.handleMultiSelectChange}
                                                name={"transports"}
                                                value={this.state.transports}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-6">
                                        <div className="form-group">
                                            <label htmlFor="cargo_type">Cargo Type*</label>
                                            <select className={"form-select"} name={"cargo_type"} id={"cargo_type"} required value={this.state.cargo_type} onChange={this.handleChange}>
                                                <option value="">Select Cargo Type</option>
                                                <option value="vehicles">Vehicles</option>
                                                <option value="coils">Coils</option>
                                                <option value="container">Container</option>
                                                <option value="ingo">Ingo</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>

                                <div className="row mt-3">
                                <div className="col-12 col-md-6">
                                    <div className="form-group">
                                        <h6>Joint survey</h6>
                                        <div className="form-check">
                                            <input onChange={this.radioSelected} value="true" className="form-check-input" checked={this.state.jointSurvey === true} type="radio" name="jointSurvey"
                                                   id="flexRadioDefault1">
                                            </input>Yes
                                        </div>
                                        <div className="form-check">
                                            <input onChange={this.radioSelected} value="false" className="form-check-input" checked={this.state.jointSurvey === false} type="radio" name="jointSurvey"
                                                   id="flexRadioDefault2">
                                            </input>No
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 col-md-6">
                                    <div className="form-group">
                                        <h6>Receiving party</h6>
                                        <div className="form-check">
                                            <input onChange={this.radioSelected} value="true" className="form-check-input" checked={this.state.receivingParty === true} type="radio" name="receivingParty"
                                                   id="flexRadioDefault1">
                                            </input>Yes
                                        </div>
                                        <div className="form-check">
                                            <input onChange={this.radioSelected} value="false" className="form-check-input" checked={this.state.receivingParty === false} type="radio" name="receivingParty"
                                                   id="flexRadioDefault2">
                                            </input>No
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row mt-3">
                                <div className="col-12 col-md-6">
                                    <div className="form-group">
                                        <h6>Sending party</h6>
                                        <div className="form-check">
                                            <input onChange={this.radioSelected} value="true" className="form-check-input" checked={this.state.sendingParty === true} type="radio" name="sendingParty"
                                                   id="flexRadioDefault1">
                                            </input>Yes
                                        </div>
                                        <div className="form-check">
                                            <input onChange={this.radioSelected} value="false" className="form-check-input" checked={this.state.sendingParty === false} type="radio" name="sendingParty"
                                                   id="flexRadioDefault2">
                                            </input>No
                                        </div>
                                    </div>
                                </div>
                            </div>

                                <div className="row mt-3">
                                    <div className="col-12 col-md-6">
                                        <div className="form-group">
                                            <h6>Is order RID TYPE? (If yes is selected then change damageset to RID)</h6>
                                            <div className="form-check">
                                                <input value="true" onChange={this.radioSelected} className="form-check-input" checked={this.state.isRidOrderType === true} type="radio" name="isRidOrderType"
                                                    id="flexRadioDefault1">
                                                </input>Yes
                                            </div>
                                            <div className="form-check">
                                                <input onChange={this.radioSelected} value="false" className="form-check-input" checked={this.state.isRidOrderType === false} type="radio" name="isRidOrderType"
                                                    id="flexRadioDefault2">
                                                </input>No
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row mt-3">
                                    <div className="col-12">
                                        <div className="form-group">
                                            <label htmlFor="notes">Notes</label>
                                            <textarea value={this.state.notes} className={"form-control"} onChange={this.handleChange} name="notes" id="notes" cols="30" rows="5"></textarea>
                                        </div>
                                    </div>
                                </div>
                                <div className="row mt-3">
                                    <div className="col-12">
                                        <FileUploader
                                            onFileSelect={(file) => this.setSelectedFile(file)}
                                            onFileSelectError={({ error }) => alert(error)}
                                        />
                                    </div>
                                </div>

                                <div className="row mt-3">
                                    <div className="col-12">
                                        <button type={"submit"} className={"btn btn-primary"}>Submit</button>
                                    </div>
                                </div>

                                <div className="row mt-3">
                                    <div className="col-12">
                                        <h2>Existing order images</h2>
                                        <div className="edit-order-gallery">
                                            <Gallery
                                                type={"Order"}
                                                images={this.state.existingImages}
                                                allowDelete={true}
                                                refreshImages={this.refreshImages}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </ConditionalContent>
                        <ConditionalContent check={isClient()}>
                            <form action="" className={"mb-5"} onSubmit={this.handleSubmit}>

                                <div className="row mb-3">
                                    <div className="col-12 col-md-6">
                                        <div className="form-group">
                                            <label htmlFor="transportType">Transport Type</label>
                                            <select className={"form-select"} name={"transportType"} id={"transportType"} value={this.state.transportType} onChange={this.handleChange}>
                                                <option disabled value="">Select Transport Type</option>
                                                <option value="vessel">Vessel</option>
                                                <option value="truck">Truck</option>
                                                <option value="train (railcar)">Train (railcar)</option>
                                                <option value="container">Container</option>
                                                <option value="shuttle">Shuttle</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-6">
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-12">
                                        <div className="form-group">
                                            <label htmlFor="name">Damage Set</label>
                                            <BaseSelect
                                                closeMenuOnSelect={true}
                                                options={this.state.damageSetsArr}
                                                name="damageSet"
                                                value={this.state.damageSet}
                                                className="basic-multi-select"
                                                classNamePrefix="select"
                                                isDisabled={this.state.damage_set_changed}
                                                onChange={this.handleSelectChange}
                                            />
                                        </div>
                                    </div>

                                </div>
                                <div className="row mt-3">
                                    <div className="col-12 col-md-6">
                                        <div className="form-group">
                                            <label htmlFor="gate">Gate</label>
                                            <select className={"form-select"} name={"gate"} id={"gate"} value={this.state.gate} onChange={this.handleChange}>
                                                <option disabled value="">Select gate</option>
                                                <option value="arrival">Arrival</option>
                                                <option value="departure">Departure</option>
                                                <option value="internal">Internal</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-6">
                                        <div className="form-group">
                                            <label htmlFor="inspectionType">Inspection Type</label>
                                            {this.inspectionTypeSelect()}
                                        </div>
                                    </div>
                                </div>
                                <div className="row mt-3">
                                    <div className="col-12 col-md-6">
                                        <div className="form-group">
                                            <label htmlFor="transports">Transports</label>
                                            <BaseSelect
                                                closeMenuOnSelect={true}
                                                isMulti
                                                options={this.state.transportsArr}
                                                onChange={this.handleMultiSelectChange}
                                                name={"transports"}
                                                value={this.state.transports}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-6">
                                        <div className="form-group">
                                            <label htmlFor="cargo_type">Cargo Type*</label>
                                            <select className={"form-select"} name={"cargo_type"} id={"cargo_type"} required value={this.state.cargo_type} onChange={this.handleChange}>
                                                <option value="">Select Cargo Type</option>
                                                <option value="vehicles">Vehicles</option>
                                                <option value="coils">Coils</option>
                                                <option value="container">Container</option>
                                                <option value="ingo">Ingo</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div className="row mt-3">
                                    <div className="col-12">
                                        <div className="form-group">
                                            <label htmlFor="notes">Notes</label>
                                            <textarea value={this.state.notes} className={"form-control"} onChange={this.handleChange} name="notes" id="notes" cols="30" rows="5"></textarea>
                                        </div>
                                    </div>
                                </div>
                                <div className="row mt-3">
                                    <div className="col-12">
                                        <FileUploader
                                            onFileSelect={(file) => this.setSelectedFile(file)}
                                            onFileSelectError={({ error }) => alert(error)}
                                        />
                                    </div>
                                </div>

                                <div className="row mt-3">
                                    <div className="col-12">
                                        <button type={"submit"} className={"btn btn-primary"}>Submit</button>
                                    </div>
                                </div>

                                <div className="row mt-3">
                                    <div className="col-12">
                                        <h2>Existing order images</h2>
                                        <div className="edit-order-gallery">
                                            <Gallery
                                                type={"Order"}
                                                images={this.state.existingImages}
                                                allowDelete={true}
                                                refreshImages={this.refreshImages}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </ConditionalContent>

                        
                        <ConditionalContent check={isManager()}>
                            <form action="" className={"mb-5"} onSubmit={this.handleSubmit}>

                                <div className="row mb-3">
                                    <div className="col-12 col-md-6">
                                        <div className="form-group">
                                            <select className={"form-select"} name={"transportType"} id={"transportType"} value={this.state.transportType} onChange={this.handleChange}>
                                                <option disabled value="">Select Transport Type</option>
                                                <option value="vessel">Vessel</option>
                                                <option value="truck">Truck</option>
                                                <option value="train (railcar)">Train (railcar)</option>
                                                <option value="container">Container</option>
                                                <option value="shuttle">Shuttle</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-6">
                                        <div className="form-group">
                                            <label htmlFor="client">Client*</label>
                                            <FixRequiredSelect
                                                SelectComponent={BaseSelect}
                                                closeMenuOnSelect={true}
                                                options={this.state.clientsArr}
                                                onChange={this.handleSelectChange}
                                                value={this.state.client}
                                                name={"client"}
                                                required
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div className="row mb-3">
                                    <div className="col-12 col-md-6">
                                        <div className="form-group">
                                            <label htmlFor="owner">Order manager*</label>

                                            <FixRequiredSelect
                                                value={this.state.admin}
                                                SelectComponent={BaseSelect}
                                                closeMenuOnSelect={true}
                                                options={this.state.adminsArr}
                                                onChange={this.handleSelectChange}
                                                name={"admin"}
                                                isDisabled={true}
                                                required
                                            />
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-6">
                                        <div className="form-group">
                                            <label htmlFor="name">Inspectors</label>
                                            <FixRequiredSelect
                                                SelectComponent={BaseSelect}
                                                closeMenuOnSelect={true}
                                                isMulti
                                                name={"inspectors"}
                                                value={this.state.inspectors}
                                                options={this.state.inspectorsArr}
                                                onChange={this.handleMultiSelectChange}
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-12">
                                        <div className="form-group">
                                            <label htmlFor="name">Damage Set</label>
                                            <BaseSelect
                                                closeMenuOnSelect={true}
                                                options={this.state.damageSetsArr}
                                                name="damageSet"
                                                value={this.state.damageSet}
                                                className="basic-multi-select"
                                                classNamePrefix="select"
                                                isDisabled={this.state.damage_set_changed}
                                                onChange={this.handleSelectChange}
                                            />
                                        </div>
                                    </div>

                                </div>
                                <div className="row mt-3">
                                    <div className="col-12 col-md-6">
                                        <div className="form-group">
                                            <label htmlFor="gate">Gate</label>
                                            <select className={"form-select"} name={"gate"} id={"gate"} value={this.state.gate} onChange={this.handleChange}>
                                                <option disabled value="">Select gate</option>
                                                <option value="arrival">Arrival</option>
                                                <option value="departure">Departure</option>
                                                <option value="internal">Internal</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-6">
                                        <div className="form-group">
                                            <label htmlFor="inspectionType">Inspection Type</label>
                                            {this.inspectionTypeSelect()}
                                        </div>
                                    </div>
                                </div>
                                <div className="row mt-3">
                                    <div className="col-12 col-md-6">
                                        <div className="form-group">
                                            <label htmlFor="transports">Transports</label>
                                            <BaseSelect
                                                closeMenuOnSelect={true}
                                                isMulti
                                                options={this.state.transportsArr}
                                                onChange={this.handleMultiSelectChange}
                                                name={"transports"}
                                                value={this.state.transports}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-6">
                                        <div className="form-group">
                                            <label htmlFor="cargo_type">Cargo Type*</label>
                                            <select className={"form-select"} name={"cargo_type"} id={"cargo_type"} required value={this.state.cargo_type} onChange={this.handleChange}>
                                                <option value="">Select Cargo Type</option>
                                                <option value="vehicles">Vehicles</option>
                                                <option value="coils">Coils</option>
                                                <option value="container">Container</option>
                                                <option value="ingo">Ingo</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div className="row mt-3">
                                    <div className="col-12">
                                        <div className="form-group">
                                            <label htmlFor="notes">Notes</label>
                                            <textarea value={this.state.notes} className={"form-control"} onChange={this.handleChange} name="notes" id="notes" cols="30" rows="5"></textarea>
                                        </div>
                                    </div>
                                </div>
                                <div className="row mt-3">
                                    <div className="col-12">
                                        <FileUploader
                                            onFileSelect={(file) => this.setSelectedFile(file)}
                                            onFileSelectError={({ error }) => alert(error)}
                                        />
                                    </div>
                                </div>

                                <div className="row mt-3">
                                    <div className="col-12">
                                        <button type={"submit"} className={"btn btn-primary"}>Submit</button>
                                    </div>
                                </div>

                                <div className="row mt-3">
                                    <div className="col-12">
                                        <h2>Existing order images</h2>
                                        <div className="edit-order-gallery">
                                            <Gallery
                                                type={"Order"}
                                                images={this.state.existingImages}
                                                allowDelete={true}
                                                refreshImages={this.refreshImages}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </ConditionalContent>
                    </div>
                </div>
            </div>
        );
    }
}

EditOrder.propTypes = {};

export default EditOrder;
